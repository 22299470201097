import {useEffect, useRef, useState} from 'react';
import {
    Avatar,
    Badge,
    Box,
    Button,
    IconButton,
    Link,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Popover,
    Tooltip
} from '@material-ui/core';

import {SubTitle1, Title2} from '../widgets/labels';
import {BellIcon, FingerPrintIcon} from '../../icons';
import axios from '../../lib/axios';
import {URL} from '../../config/constants';

const iconsMap = {
    password: FingerPrintIcon,
};

const NotificationsPopover = () => {
    const anchorRef = useRef(null);
    const [open, setOpen] = useState(false);
    const [notifications, setNotifications] = useState([]);

    useEffect(async () => {
        const res = await axios.get(URL.api.notifications);
        console.log('[NotificationsPopover]', res.data);
        setNotifications(res.data);
    }, []);

    const handleOpen = () => setOpen(true);

    const handleClose = () => setOpen(false);

    const body = notifications.length === 0
        ? (
            <Box sx={{p: 2}}>
                <SubTitle1 text="There are no notifications"/>
            </Box>
        )
        : (
            <>
                <List disablePadding>
                    {notifications.map(notification => {
                        const Icon = iconsMap[notification.type];

                        return (
                            <ListItem divider key={notification._id}>
                                <ListItemAvatar>
                                    <Avatar
                                        sx={{
                                            backgroundColor: 'primary.main',
                                            color: 'primary.contrastText'
                                        }}
                                    >
                                        <Icon fontSize="small"/>
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={(
                                        <Link
                                            color="textPrimary"
                                            sx={{cursor: 'pointer'}}
                                            underline="none"
                                            variant="subtitle2"
                                        >
                                            {notification.title}
                                        </Link>
                                    )}
                                    secondary={notification.body}
                                />
                            </ListItem>
                        );
                    })}
                </List>
                <Box sx={{display: 'flex', justifyContent: 'center', p: 1}}>
                    <Button
                        color="primary"
                        size="small"
                        variant="text"
                        onClick={()=>setNotifications([])}
                    >Mark all as read</Button>
                </Box>
            </>
        );

    return (
        <>
            <Tooltip title="Notifications">
                <IconButton
                    color="inherit"
                    ref={anchorRef}
                    onClick={handleOpen}
                >
                    <Badge color="error" badgeContent={notifications.length}>
                        <BellIcon fontSize="small"/>
                    </Badge>
                </IconButton>
            </Tooltip>
            <Popover
                anchorEl={anchorRef.current}
                anchorOrigin={{horizontal: 'center', vertical: 'bottom'}}
                onClose={handleClose}
                open={open}
                PaperProps={{sx: {width: 320}}}
            >
                <Box sx={{p: 2}}>
                    <Title2 text="Notifications"/>
                </Box>
                {body}
            </Popover>
        </>
    );
};

export default NotificationsPopover;
