import * as actionTypes from "./actionTypes";
import { setLoading } from "./common";
import axios from "../../lib/axios";
import { ID, OPTIONS, STORE, URL } from "../../config/constants";
import { getName, getName2, isObjectValid, toISODate } from "../../utils/utils";
import { getDepartments } from "./department";

const addEmployee = (dispatch, employee) =>
  dispatch({ type: actionTypes.ADD_EMPLOYEE, employee });
const editEmployee = (dispatch, employee) =>
  dispatch({ type: actionTypes.UPDATE_EMPLOYEE, employee });
const removeEmployee = (dispatch, employee) =>
  dispatch({ type: actionTypes.DELETE_EMPLOYEE, employee });
const setEmployees = (dispatch, employees) =>
  dispatch({ type: actionTypes.SET_EMPLOYEES, employees });

export const getEmployees = async (dispatch) => {
  setLoading(dispatch, true);
  const res = await axios.get(URL.api.employees + "?active=true");
  console.log("[employee.getEmployees]", res.data);
  setEmployees(dispatch, formatEmployees(res.data));
  return res.data;
};

// export const getEmployee = async (dispatch, employeeId) => {
//     setLoading(dispatch, true);
//     const res = await axios.get(URL.api.employees + employeeId);
//     console.log('[employee.getEmployee]', res.data);
//     formatEmployee(res.data);
// };

export const createEmployee = async (dispatch, employee, tenantId) => {
  setLoading(dispatch, true);
  const res = await axios.post(
    URL.api.employees,
    formatEmployee4Db({ ...employee, tenantId })
  );
  console.log("[employee.createEmployee] Response:", res.data);
  addEmployee(dispatch, combineEmployee(employee, res.data));
};

export const updateEmployee = async (dispatch, employee) => {
  setLoading(dispatch, true);
  await axios.put(URL.api.employees + employee.id, formatEmployee4Db(employee));
  editEmployee(dispatch, addFullName(employee));
};

export const deleteEmployee = async (dispatch, employee) => {
  setLoading(dispatch, true);
  await axios.delete(URL.api.employees + employee.id);
  removeEmployee(dispatch, employee);
};

export const syncEmployees = async (dispatch) => {
  setLoading(dispatch, true);
  const res = await axios.get(URL.api.sync);
  console.log("[employee.syncEmployees]", res.data);
  setEmployees(dispatch, formatEmployees(res.data));

  await getDepartments();
};

const formatEmployees = (employees) =>
  employees.map((employee) => formatEmployee(employee));

export const formatEmployee = (emp) => {
  let newEmployee = {
    [ID.id]: emp._id,
    [ID.tenantId]: emp.tenantId,
    [ID.photo]: emp.photo,
    [ID.fName]: emp.basic.name.first,
    [ID.lName]: emp.basic.name.last,
    [ID.name]: getName(emp.basic.name),
    [ID.empId]: emp.employeeId,
    [ID.cert]: emp.cert,
    [ID.jobNo]: emp.jobNo,
    [ID.pos]: emp.occupation,
    [ID.gender]: emp.basic.gender,
    [ID.race]: emp.basic.race,
    [ID.marital]: emp.family && emp.family.marital,
    [ID.nationality]: emp.basic.nationality,
    [ID.ic]: emp.basic.ic && emp.basic.ic.no,
    [ID.dob]: emp.basic.dateOfBirth && toISODate(emp.basic.dateOfBirth),
    [ID.supervisor]: isObjectValid(emp.supervisor)
      ? emp.supervisor._id
      : emp.supervisor,
    [ID.doj]: emp.dateOfJoin && toISODate(emp.dateOfJoin.start),
    [ID.remark]: emp.remark,
  };

  if (emp.company) {
    newEmployee[ID.company] = emp.company.name;
    newEmployee[ID.companyId] = emp.company._id;
  }

  if (emp.site) {
    newEmployee[ID.site] = emp.site._id;
    newEmployee[ID.siteName] = emp.site.name;
  }

  if (emp.department) {
    newEmployee[ID.deptId] = emp.department._id;
    newEmployee[ID.dept] = emp.department.name;
    newEmployee[ID.deptMgr] = emp.department.manager;
  }

  if (emp.contact) {
    newEmployee[ID.email] = emp.contact.email;
    if (emp.contact.address) {
      newEmployee[ID.addr1] = emp.contact.address.line1;
      newEmployee[ID.addr2] = emp.contact.address.line2;
      newEmployee[ID.postal] = emp.contact.address.postCode;
    }
    if (emp.contact.phone) {
      newEmployee[ID.contact] = emp.contact.phone;
    }
  }

  if (emp.user) {
    newEmployee[ID.account] = emp.user._id;
    newEmployee[ID.role] = emp.user.role;
  }

  // console.log('[employee.formatEmployee]', emp, newEmployee);
  return newEmployee;
};

const formatEmployee4Db = (emp) => {
  const dbEmployee = {
    tenantId: emp.tenantId,
    basic: {
      name: {
        first: emp[ID.fName],
        last: emp[ID.lName],
      },
      race: emp[ID.race],
      gender: emp[ID.gender],
      dateOfBirth: emp[ID.dob],
      nationality: emp[ID.nationality],
    },
    family: {
      marital: emp[ID.marital],
    },
    photo: emp[ID.photo],
    cert: emp[ID.cert],
    occupation: emp[ID.pos],
    jobNo: emp[ID.jobNo],
    supervisor: emp[ID.supervisor],
    company: emp[ID.companyId],
    site: emp[ID.site],
    department: emp[ID.deptId] || emp[ID.site] || emp[ID.companyId],
    contact: {
      phone: emp[ID.contact],
      email: emp[ID.email],
      address: {
        line1: emp[ID.addr1],
        line2: emp[ID.addr2],
        postCode: emp[ID.postal],
      },
    },
    dateOfJoin: {
      start: emp[ID.doj],
    },
    remark: emp[ID.remark],
    account: emp[ID.account],
    role: emp[ID.role],
  };

  if (emp[ID.id]) {
    dbEmployee._id = emp[ID.id];
  }
  if (emp[ID.empId]) {
    dbEmployee.employeeId = emp[ID.empId];
  }
  if (emp[ID.ic]) {
    dbEmployee.basic.ic = { no: emp[ID.ic] };
  }

  // console.log('[employee.formatEmployee4Db]', dbEmployee);
  return dbEmployee;
};

const combineEmployee = (local, db) => ({
  ...addFullName(local),
  [ID.id]: db._id,
  [ID.empId]: db.employeeId,
});

const addFullName = (empForm) => ({
  ...empForm,
  [ID.name]: getName2(empForm),
});
