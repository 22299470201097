import {useRef, useState} from 'react';
import {Link as RouterLink, useNavigate} from 'react-router-dom';
import {useSnackbar} from 'notistack';
import {
    Avatar,
    Box,
    Button,
    ButtonBase,
    Divider,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Popover
} from '@material-ui/core';
import useAuth from '../../hooks/useAuth';
import UserIcon from '../../icons/User';
import {getName2} from '../../utils/utils';
import {ID, OPTIONS, UI, URL} from '../../config/constants';
import {CogIcon} from '../../icons';
import {SubTitle1, SubTitle2} from '../widgets/labels';

const AccountPopover = () => {
    const anchorRef = useRef(null);
    const {user, logout} = useAuth();
    const navigate = useNavigate();
    const {enqueueSnackbar} = useSnackbar();
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleLogout = async () => {
        try {
            handleClose();
            await logout();
            navigate('/');
        } catch (err) {
            console.error(err);
            enqueueSnackbar('Unable to logout', {
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top'
                },
                variant: 'error'
            });
        }
    };

    const name = getName2(user.employee);
    const department = user.employee[ID.dept];

    const avatar = (
        <Box
            component={ButtonBase}
            onClick={handleOpen}
            ref={anchorRef}
            sx={{
                alignItems: 'center',
                display: 'flex'
            }}
        >
            <Avatar
                alt={name}
                src={user.employee.photo}
                sx={{
                    height: 32,
                    width: 32
                }}
            />
        </Box>
    );

    const title = (
        <Box sx={{p: 2}}>
            <SubTitle1 text={name}/>
            <SubTitle2 text={department}/>
        </Box>
    );

    const profile = (
        <MenuItem
            component={RouterLink}
            to={URL.account}
            onClick={handleClose}
        >
            <ListItemIcon>
                <UserIcon fontSize="small"/>
            </ListItemIcon>
            <ListItemText primary={<SubTitle1 text="Profile"/>} />
        </MenuItem>
    );

    const password = (
        <MenuItem
            component={RouterLink}
            to={URL.account + '?tab=' + UI.accTabs[2].value}
            onClick={handleClose}
            // onClick={()=>navigate(URL.account + '/' + rowData.id)}
        >
            <ListItemIcon>
                <CogIcon fontSize="small"/>
            </ListItemIcon>
            <ListItemText primary={<SubTitle1 text="Password"/>} />
        </MenuItem>
    );

    return (
        <>
            {avatar}
            <Popover
                anchorEl={anchorRef.current}
                anchorOrigin={{
                    horizontal: 'center',
                    vertical: 'bottom'
                }}
                keepMounted
                onClose={handleClose}
                open={open}
                PaperProps={{
                    sx: {width: 240}
                }}
            >
                {title}
                <Divider/>
                <Box sx={{mt: 2}}>
                    {profile}
                    {password}
                </Box>
                <Box sx={{p: 2}}>
                    <Button
                        color="primary"
                        fullWidth
                        onClick={handleLogout}
                        variant="outlined"
                    >Logout</Button>
                </Box>
            </Popover>
        </>
    );
};

export default AccountPopover;
