import {useState, useEffect} from "react";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import Fab from "@material-ui/core/Fab";
import Grid from "@material-ui/core/Grid";
import red from "@material-ui/core/colors/red";
import blue from "@material-ui/core/colors/blue";
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";
import {withStyles} from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';

import {Tooltip} from '.';

const styles = theme => ({
    title: {
        fontSize: 14,
    },
    error: {
        color: red[900],
        marginTop: 20,
        fontSize: 14,
    },
    icon: {
        margin: theme.spacing(2)
    },
    button: {
        color: blue[900],
        margin: 10
    },
});

const ImageUploadCard = props => {
    const {image, onUpload, style, classes, err, disabled} = props;

    const [mainState, setMainState] = useState('initial');
    const [imageUploaded, setImageUploaded] = useState(0);
    const [selectedFile, setSelectedFile] = useState(null);
    const [error, setError] = useState('');

    const loadImage = () => {
        if (image) {
            setMainState('uploaded');
            setSelectedFile(image);
        }
    }

    useEffect(() => {
        loadImage();
    }, [image])

    useEffect(() => {
        setError(err);
        image ? loadImage() : imageResetHandler();
    }, [err])

    const handleUploadClick = event => {
        const file = event.target.files[0];
        const fileTypes = /\/jpg|jpeg|gif|png$/i;
        if (!file || file.size > 2048000 || !fileTypes.test(file.type)) {
            return setError('Image size limit: 2 MB');
        }

        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            setMainState('uploaded');
            setImageUploaded(1);
            setSelectedFile(reader.result)
            setError('');
            onUpload(reader.result);
        };
    };

    const renderInitialState = () => (
        <CardContent>
            <Typography
                className={classes.title}
                color="textPrimary"
                gutterBottom
            >Upload Photo *</Typography>
            <Grid container justify="center" alignItems="center">
                {!disabled ? <input
                    id="upload-file"
                    accept="image/*"
                    type="file"
                    hidden
                    disabled={disabled}
                    onChange={handleUploadClick}
                /> : null}
                <label htmlFor="upload-file">
                    <Fab component="span" className={classes.button}>
                        <Tooltip tip="Upload local image, size limit: 2 MB" position="bottom">
                            <AddPhotoAlternateIcon fontSize="large"/>
                        </Tooltip>
                    </Fab>
                </label>
            </Grid>
            <Typography className={classes.error}>
                {error}
            </Typography>
        </CardContent>
    );

    const renderUploadedState = () => (
        <CardActionArea onClick={imageResetHandler}>
            <img
                alt="resident"
                width="100%"
                src={selectedFile}
            />
        </CardActionArea>
    );

    const imageResetHandler = () => {
        if(!disabled) {
            setMainState('initial');
            setImageUploaded(0);
            setSelectedFile(null)
        }
    };

    return (
        <Card style={style}>
            {(mainState === "initial" && renderInitialState()) ||
            (mainState === "uploaded" && renderUploadedState())}
        </Card>
    );
}

export default withStyles(styles, {withTheme: true})(ImageUploadCard);
