import * as actionTypes from './actionTypes';
import {setLoading, setError} from './common';
import axios from "../../lib/axios";
import {ERROR, ID, URL} from '../../config/constants';
import {getName} from '../../utils/utils';

const setDepartments = (dispatch, departments) => dispatch({type: actionTypes.SET_DEPARTMENTS, departments});
const removeDept = (dispatch, id) => dispatch({type: actionTypes.DELETE_DEPARTMENT, id});
const addDept = (dispatch, department) => dispatch({type: actionTypes.ADD_DEPARTMENT, department});
const updateDept = (dispatch, department) => dispatch({type: actionTypes.UPDATE_DEPARTMENT, department});

export const getDepartments = async dispatch => {
    setLoading(dispatch, true);
    try {
        const res = await axios.get(URL.api.department);
        const departments = formatDepartments(res.data);
        console.log('[department.getDepartments] response:', res.data, ', formatted:', departments);
        setDepartments(dispatch, departments);
    } catch (err) {
        setError(dispatch, ERROR.getDepartments);
        // throw Error(err.response);
    }
};

export const createDepartment = async (dispatch, dept) => {
    setLoading(dispatch, true);
    const res = await axios.post(URL.api.department, formatDept4Db(dept));
    console.log('[department.createDepartment] Response:', res.data);
    addDept(dispatch, {...dept, [ID.id]: res.data._id});
};

export const updateDepartment = async (dispatch, dept) => {
    setLoading(dispatch, true);
    const res = await axios.put(
        URL.api.department + dept.id,
        formatDept4Db(dept)
    );
    console.log('[department.updateDepartment] Response:', res.data);
    updateDept(dispatch, dept);
};

export const deleteDepartment = async (dispatch, id) => {
    setLoading(dispatch, true);
    await axios.delete(URL.api.department + id);
    removeDept(dispatch, id);
};

const formatDepartments = depts =>
    depts && depts.map(dept => formatDepartment(dept));

const formatDepartment = dept => {
    const newDept = {
        [ID.id]: dept._id,
        [ID.name]: dept.name,
        [ID.remark]: dept.remark
    };

    if(dept.type)
        newDept[ID.type] = dept.type;

    if(dept.deptId)
        newDept[ID.deptId] = dept.deptId;

    if(dept.parent) {
        newDept[ID.parent] = dept.parent._id;
        newDept[ID.pId] = dept.parent.deptId;
        newDept[ID.pName] = dept.parent.name;
    }

    if(dept.manager) {
        const basic = dept.manager.basic;
        if(basic && basic.name)
            newDept[ID.mName] = getName(basic.name);
        newDept[ID.mId] = dept.manager._id;
        newDept[ID.mPhoto] = dept.manager.photo;
    }

    return newDept;
}

const formatDept4Db = dept => ({
    deptId: dept[ID.deptId],
    name: dept[ID.name],
    type: dept[ID.type],
    parent: dept[ID.parent],
    pId: dept[ID.pId],
    pName: dept[ID.pName],
    manager: dept[ID.mId],
    remark: dept[ID.remark]
})
