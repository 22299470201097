const sx = {
    body: {
        backgroundColor: 'background.default',
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh'
    },
    logoBox: {
        display: 'flex',
        justifyContent: 'center',
        mb: 8
    },
    logo: {
        height: 40,
        width: 40
    },
    main: {
        display: 'flex',
        flexDirection: 'column',
        p: 4
    },
    title: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
        mb: 3
    },
    form: {
        flexGrow: 1,
        mt: 3
    }
};

export default sx;
