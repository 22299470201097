import {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {InputLabel, MenuItem, FormControl, Select, OutlinedInput} from '@material-ui/core';
import {capitalize} from 'lodash/string';
import {LABEL, OPTIONS, UI} from '../../../config/constants';
import {Tooltip} from '.';

const useStyles = makeStyles(theme => ({
    formControl: {
        margin: theme.spacing(2),
        fullWidth: true,
        width: UI.input.width,
        minWidth: UI.input.minWidth,
        maxWidth: UI.input.maxWidth,
    },
}));

const SelectSin = props => {
    const classes = useStyles();
    const {id, value, required, disabled, onChange, tip, style} = props;
    const label = LABEL[id];
    const options = OPTIONS[id];
    const [selected, setSelected] = useState('');

    useEffect(() => {
        setSelected(value || '');
    }, [value]);

    const changeHandler = event => {
        setSelected(event.target.value);
        onChange && onChange(event);
    }

    const body = (
        <FormControl
            required={required}
            disabled={disabled}
            className={classes.formControl}
            style={style}
        >
            <InputLabel id={id + '-label'}>{label}</InputLabel>
            <Select
                fullWidth
                id={id}
                labelId={id + '-label'}
                name={id}
                value={selected}
                onChange={changeHandler}
                input={<OutlinedInput label={label}/>}
            >
                {options
                    ? options.map((option, id) => <MenuItem key={id} value={option}>{capitalize(option)}</MenuItem>)
                    : null}
            </Select>
        </FormControl>
    );

    return (
        <div>
            {tip ? <Tooltip tip={tip}>{body}</Tooltip> : body}
        </div>
    );
}

export default SelectSin;
