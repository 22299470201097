import { Suspense, lazy } from "react";
import AuthGuard from "./components/guard/AuthGuard";
import DashboardLayout from "./components/dashboard/DashboardLayout";
import GuestGuard from "./components/guard/GuestGuard";
import LoadingScreen from "./components/LoadingScreen";
import MainLayout from "./components/MainLayout";
import RoleGuard from "./components/guard/RoleGuard";
import { ID, URL } from "./config/constants";

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// Authentication pages
const Login = Loadable(lazy(() => import("./pages/authentication/Login")));
const ForgotPassword = Loadable(
  lazy(() => import("./pages/authentication/ForgotPassword"))
);
const ActivateAccount = Loadable(
  lazy(() => import("./pages/authentication/ActivateAccount"))
);
const OTP = Loadable(lazy(() => import("./pages/authentication/OTP")));

// Dashboard pages
const Account = Loadable(lazy(() => import("./pages/dashboard/Account")));
const Calendar = Loadable(lazy(() => import("./pages/dashboard/Calendar")));
const Attendances = Loadable(
  lazy(() => import("./pages/dashboard/Attendances"))
);
const AttendancesDetailed = Loadable(lazy(() => import('./pages/dashboard/AttendancesDetailed')));
const AccessControl = Loadable(
  lazy(() => import("./pages/dashboard/AccessControl"))
);
const Employees = Loadable(lazy(() => import("./pages/dashboard/Employees")));
const Departments = Loadable(
  lazy(() => import("./pages/dashboard/Departments"))
);
const Devices = Loadable(lazy(() => import("./pages/dashboard/Devices")));
const Snapshot = Loadable(lazy(() => import('./pages/dashboard/Snapshot')));
const Remote = Loadable(lazy(() => import("./pages/dashboard/Remote")));
const Telegroups = Loadable(lazy(() => import("./pages/dashboard/Telegroups")));
const OrganizationChart = Loadable(
  lazy(() => import("./pages/dashboard/Organization"))
);
const EmployeeInfo = Loadable(
  lazy(() => import("./pages/dashboard/EmployeeInfo"))
);
const InvoiceList = Loadable(
  lazy(() => import("./pages/dashboard/InvoiceList"))
);
const Overview = Loadable(lazy(() => import("./pages/dashboard/Overview")));
//* Newly added to Dashboard pages
const Shift = Loadable(lazy(() => import("./pages/dashboard/Shift")));
const Exit = Loadable(lazy(() => import("./pages/dashboard/Exit")));

// Error pages
const AuthorizationRequired = Loadable(
  lazy(() => import("./pages/AuthorizationRequired"))
);
const NotFound = Loadable(lazy(() => import("./pages/NotFound")));
const ServerError = Loadable(lazy(() => import("./pages/ServerError")));
const UnderConstruction = Loadable(
  lazy(() => import("./pages/UnderConstruction"))
);

const admin = [ID.admin];
const manager = admin.concat(ID.manager);

const routes = [
  {
    path: URL.auth.base,
    children: [
      {
        path: URL.auth.login,
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        ),
      },
      {
        path: URL.auth.forgotPwd,
        element: <ForgotPassword />,
      },
      {
        path: URL.auth.activateAcct,
        element: <ActivateAccount />,
      },
      {
        path: URL.auth.otp,
        element: <OTP />,
      },
    ],
  },
  {
    path: "/",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "/",
        element: <Overview />,
      },
      {
        path: URL.account,
        element: <Account />,
      },
      {
        path: URL.attendance.base,
        children: [
          {
            path: "/",
            element: <Attendances />,
          },
          {
						path: URL.attendance.detailedReport,
						element: <AttendancesDetailed/>
					},
          {
            path: URL.attendance.accessCtl,
            element: (
              <RoleGuard roles={admin}>
                <AccessControl />
              </RoleGuard>
            ),
          },
        ],
      },
      {
        path: URL.shifts.manage,
        element: <Shift />,
      },
      {
        path: URL.exit.manage,
        element: <Exit />,
      },
      {
        path: URL.emp.base,
        children: [
          {
            path: "/",
            element: <Employees />,
          },
          {
            path: URL.emp.info,
            element: <EmployeeInfo />,
          },
          {
            path: URL.emp.new,
            element: (
              <RoleGuard roles={manager}>
                <EmployeeInfo />
              </RoleGuard>
            ),
          },
          {
            path: URL.emp.dept,
            element: <Departments />,
          },
          {
            path: URL.emp.org,
            element: <OrganizationChart />,
          },
        ],
      },
      {
        path: URL.device,
        element: (
          <RoleGuard roles={admin}>
            <Devices />
          </RoleGuard>
        ),
      },
      {
        path: URL.rmacess.base,
        children: [
          {
            path: "/",
            element: <Remote />,
          },
          {
            path: URL.rmacess.remoteSn,
            element: <Remote />,
          },
          {
            path: URL.telegram,
            element: (
              <RoleGuard roles={admin}>
                <Telegroups />
              </RoleGuard>
            ),
          },
        ],
      },
      {
          path: URL.snapshot,
          element: (
              <RoleGuard roles={admin}>
                  <Snapshot/>
              </RoleGuard>
          )
      },

      {
        path: URL.leave.base,
        children: [
          {
            path: "/",
            element: <Calendar />,
          },
          {
            path: URL.leave.setup,
            element: <UnderConstruction />,
          },
          {
            path: URL.leave.approve,
            element: <UnderConstruction />,
          },
        ],
      },
      {
        path: URL.expense.base,
        children: [
          {
            path: "/",
            element: <InvoiceList />,
          },
          {
            path: URL.expense.approve,
            element: <UnderConstruction />,
          },
        ],
      },
    ],
  },
  {
    path: "*",
    element: <MainLayout />,
    children: [
      {
        path: "401",
        element: <AuthorizationRequired />,
      },
      {
        path: "404",
        element: <NotFound />,
      },
      {
        path: "500",
        element: <ServerError />,
      },
      {
        path: "*",
        element: <NotFound />,
      },
    ],
  },
];

export default routes;
