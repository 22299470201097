import { useEffect } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  Link,
  Typography,
} from "@material-ui/core";
import { PhonelinkSetup, RoomPreferences, Camera } from "@material-ui/icons";

import useAuth from "../../hooks/useAuth";
import {
  ChartSquareBarIcon,
  ClockIcon,
  CurrencyDollarIcon,
  HomeIcon,
  UsersIcon,
} from "../../icons";
import Logo from "../Logo";
import NavSection from "../NavSection";
import Scrollbar from "../Scrollbar";
import { getName2 } from "../../utils/utils";
import { ID, OPTIONS, URL } from "../../config/constants";
import { SubTitle1 } from "../widgets/labels";

const userItems = [
    {
      title: "Dashboard",
      path: "/",
      icon: <ChartSquareBarIcon fontSize="small" />,
    },
    {
      title: "Employee Management",
      path: URL.emp.base,
      icon: <UsersIcon fontSize="small" />,
      children: [
        {
          title: "Employees",
          path: URL.emp.base,
        },
        {
          title: "Departments",
          path: URL.emp.base + URL.emp.dept,
        },
        {
          title: "Organization Chart",
          path: URL.emp.base + URL.emp.org,
        },
      ],
    },
    {
			title: 'Attendance',
			path: URL.attendance.base,
			icon: <ClockIcon fontSize="small"/>,
			children: [
				{
					title: 'Summary Report',
					path: URL.attendance.base
				},
				{
					title: 'Attendance Report',
					path: URL.attendance.base + URL.attendance.detailedReport
				},
/* 				{
					title: 'Detailed Report',
					path: URL.attendance.base + URL.attendance.deviceReport
				}, */
			]
		},
		{
            title: 'Detailed Snapshot Report',
            path: URL.snapshot,
            icon: <Camera fontSize="small"/>
    },
    // {
    //     title: 'Employee management',
    //     path: URL.emp.base,
    //     icon: <UsersIcon fontSize="small"/>
    // },
    // {
    //     title: 'Attendance report',
    //     path: URL.attendance.base,
    //     icon: <ClockIcon fontSize="small"/>
    // },
    // {
    //   title: "Attendance Management",
    //   path: URL.attendance.base,
    //   icon: <ClockIcon fontSize="small" />,
    //   children: [
    //     {
    //       title: "Attendance report",
    //       path: URL.attendance.base,
    //     },
    //     {
    //       title: "Access control",
    //       path: URL.attendance.base + URL.attendance.accessCtl,
    //     },
    //   ],
    // },
    // {
    //   title: "Shift Management",
    //   path: URL.shifts.manage,
    //   icon: <ClockIcon fontSize="small" />,
    //   children: [
    //     {
    //       title: "Shifts",
    //       path: URL.shifts.manage,
    //     },
    //     {
    //       title: "Shift Report",
    //       path: URL.shifts.report,
    //     },
    //   ],
    // },
    {
      title: "Exit Management",
      path: URL.exit.manage,
      icon: <ClockIcon fontSize="small" />,
      children: [
        {
          title: "Manage",
          path: URL.exit.manage,
        },
      ],
    },
    // {
    //     title: 'Leave management',
    //     path: URL.leave.base,
    //     icon: <HomeIcon fontSize="small"/>,
    //     children: [
    //         {
    //             title: 'Leave application',
    //             path: URL.leave.base
    //         },
    //         {
    //             title: 'Leave approval',
    //             path: URL.leave.base + URL.leave.approve
    //         },
    //         {
    //             title: 'Off day setup',
    //             path: URL.leave.base + URL.leave.setup
    //         }
    //     ]
    // },
    // {
    //     title: 'Expense management',
    //     path: URL.expense.base,
    //     icon: <CurrencyDollarIcon fontSize="small"/>,
    //     children: [
    //         {
    //             title: 'Claim submission',
    //             path: URL.expense.base
    //         },
    //         {
    //             title: 'Claim approval',
    //             path: URL.expense.base + URL.expense.approve
    //         }
    //     ]
    // },
  ],
  adminItems = [
    ...userItems,
    {
      title: "Device Management",
      path: URL.device,
      icon: <PhonelinkSetup fontSize="small" />,
    },
    {
      title: "Remote Access",
      path: URL.rmacess.base,
      icon: <RoomPreferences fontSize="small" />,
      children: [
        {
          title: "Remote Open Door",
          path: URL.rmacess.base,
        },
        {
          title: "Telegram Groups",
          path: URL.rmacess.base + URL.telegram,
        },
      ],
    },
  ];

const DashboardSidebar = (props) => {
  const { onMobileClose, openMobile } = props;
  const location = useLocation();
  const { user } = useAuth();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const name = getName2(user.employee);
  const department = user.employee[ID.dept];

  const sections =
    user.role === ID.admin ? [{ items: adminItems }] : [{ items: userItems }];

  const content = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              p: 2,
            }}
          >
            <RouterLink to="/">
              <Logo
                sx={{
                  height: 40,
                  width: 40,
                }}
              />
            </RouterLink>
          </Box>
        </Hidden>
        <Box sx={{ p: 2 }}>
          <Box
            sx={{
              alignItems: "center",
              backgroundColor: "background.default",
              borderRadius: 1,
              display: "flex",
              overflow: "hidden",
              p: 2,
            }}
          >
            <RouterLink to={URL.account}>
              <Avatar
                alt={name}
                src={user.employee.photo}
                sx={{
                  cursor: "pointer",
                  height: 48,
                  width: 48,
                }}
              />
            </RouterLink>
            <Box sx={{ ml: 2 }}>
              <SubTitle1 text={name} />
              <Typography color="textSecondary" variant="body2">
                Department:{" "}
                <Link
                  color="primary"
                  component={RouterLink}
                  to={URL.emp.base + URL.emp.org}
                >
                  {department}
                </Link>
              </Typography>
            </Box>
          </Box>
        </Box>
        <Divider />
        <Box sx={{ p: 2 }}>
          {sections.map((section, id) => (
            <NavSection
              key={id}
              pathname={location.pathname}
              sx={{ "& + &": { mt: 3 } }}
              {...section}
            />
          ))}
        </Box>
      </Scrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          PaperProps={{
            sx: {
              backgroundColor: "background.paper",
              width: 280,
            },
          }}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor="left"
          open
          PaperProps={{
            sx: {
              backgroundColor: "background.paper",
              height: "calc(100% - 64px) !important",
              top: "64px !Important",
              width: 280,
            },
          }}
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default DashboardSidebar;
