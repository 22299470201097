export const SET_ERROR = "SET_ERROR";
export const SET_LOADING = "SET_LOADING";
export const SET_HEADER = "SET_HEADER";
export const SET_DIALOG = "SET_DIALOG";

export const SET_USER = "SET_USER";
export const SET_USERS = "SET_USERS";
export const ADD_USERS = "ADD_USERS";
export const UPDATE_USERS = "UPDATE_USERS";
export const DELETE_USERS = "DELETE_USERS";

export const SET_ATTENDANCES = "SET_ATTENDANCES";
export const UPDATE_ATTENDANCE = "UPDATE_ATTENDANCE";

export const SET_EMPLOYEES = "SET_EMPLOYEES";
export const ADD_EMPLOYEE = "ADD_EMPLOYEE";
export const UPDATE_EMPLOYEE = "UPDATE_EMPLOYEE";
export const DELETE_EMPLOYEE = "DELETE_EMPLOYEE";

export const SET_DEPARTMENTS = "SET_DEPARTMENTS";
export const ADD_DEPARTMENT = "ADD_DEPARTMENT";
export const UPDATE_DEPARTMENT = "UPDATE_DEPARTMENT";
export const DELETE_DEPARTMENT = "DELETE_DEPARTMENT";

export const SET_DEVICES = "SET_DEVICES";
export const UPDATE_DEVICE = "UPDATE_DEVICE";

export const SET_SNAPSHOTS = "SET_SNAPSHOTS";

export const UPDATE_PAGE = "UPDATE_PAGE";

export const SET_TELEGROUPS = "SET_TELEGROUPS";
export const ADD_TELEGROUP = "ADD_TELEGROUP";
export const UPDATE_TELEGROUP = "UPDATE_TELEGROUP";
export const DELETE_TELEGROUP = "DELETE_TELEGROUP";

export const ADD_EXIT_GROUP = "ADD_EXIT_GROUP";
export const SET_EXIT_GROUP = "SET_EXIT_GROUP";
export const UPDATE_EXIT_GROUP = "UPDATE_EXIT_GROUP";
export const DELETE_EXIT_GROUP = "DELETE_EXIT_GROUP";
