import {useNavigate} from 'react-router-dom';
import useIsMountedRef from '../../hooks/useIsMountedRef';
import {useSnackbar} from 'notistack';
import * as Yup from 'yup';
import {Formik} from 'formik';
import {Box, Divider, FormHelperText, Grid} from '@material-ui/core';

import useAuth from '../../hooks/useAuth';
import {MSG, UI} from '../../config/constants';
import {showSnackbar} from '../../utils/utils';
import {Password} from '../widgets/input';
import {ButtonRight, FullWidthButton} from '../widgets/buttons';

const SetPassword = props => {

    const {isSelf, userId, logout, token} = props,
        isMountedRef = useIsMountedRef(),
        navigate = useNavigate(),
        {updatePassword} = useAuth(),
        {enqueueSnackbar} = useSnackbar();

    const initValues = {
            password: '',
            passwordConfirm: '',
            submit: null
        },
        validation = Yup.object().shape({
            password: Yup.string()
                .min(UI.pwd.min, `Minimum ${UI.pwd.min} characters`)
                .max(UI.pwd.max, `Maximum ${UI.pwd.max} characters`)
                .required('Required')
                .matches(
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).*$/,
                    "Must Contain One Uppercase, One Lowercase and One Number"
                ),
            passwordConfirm: Yup.string()
                .oneOf([Yup.ref('password'), null], 'Passwords must match')
                .required('Required')
        });

    const onSubmit = async (values, {setErrors, setStatus, setSubmitting}) => {
        try {
            await updatePassword(userId, values.password, isSelf, token);
            showSnackbar(enqueueSnackbar, MSG.success.pwd);
            isSelf && await logout();
            navigate('/');
        } catch (err) {
            console.error('[SetPassword.onSubmit]', err);
            if (isMountedRef.current) {
                setStatus({success: false});
                setErrors({submit: err.message});
                setSubmitting(false);
            }
        }
    }

    let bodySx, containerProps = {
        direction: 'column',
        justifyContent: 'center'
    };
    if (isSelf) {
        bodySx = {m: 5, mx: 10};
        containerProps = {
            spacing: 4,
            direction: 'Row',
            alignItems: 'center',
            justifyContent: 'center'
        };
    }

    return (
        <Formik
            initialValues={initValues}
            validationSchema={validation}
            onSubmit={onSubmit}
            validateOnMount
        >
            {({
                  isValid,
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  values
            }) => (
                <form onSubmit={handleSubmit}>
                    <Box sx={bodySx}>
                        <Grid container {...containerProps}>
                            <Grid item md={4} sm={6} xs={12}>
                                <Password
                                    error={Boolean(touched.password && errors.password)}
                                    helperText={touched.password && errors.password}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.password}
                                />
                            </Grid>
                            <Grid item md={4} sm={6} xs={12}>
                                <Password
                                    error={Boolean(touched.passwordConfirm && errors.passwordConfirm)}
                                    helperText={touched.passwordConfirm && errors.passwordConfirm}
                                    label="Password Confirmation"
                                    name="passwordConfirm"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.passwordConfirm}
                                />
                            </Grid>
                        </Grid>
                        {errors.submit && (
                            <Box sx={{mt: 3}}>
                                <FormHelperText error>
                                    {errors.submit}
                                </FormHelperText>
                            </Box>
                        )}
                    </Box>
                    {isSelf
                        ? (
                            <>
                                <Divider/>
                                <ButtonRight
                                    disabled={!isValid || isSubmitting}
                                    label="Change Password"
                                />
                            </>
                        )
                        : (
                            <FullWidthButton
                                disabled={!isValid || isSubmitting}
                                sx={{mt: 3}}
                            >Set Password</FullWidthButton>
                        )
                    }
                </form>
            )}
        </Formik>
    );
};

export default SetPassword;
