import {useEffect, useState} from "react";
import {makeStyles} from '@material-ui/core/styles';
import {TextField} from '@material-ui/core';
import {LABEL, UI} from '../../../config/constants';
import {Tooltip} from '.';

const useStyles = makeStyles(theme => ({
    input: {
        margin: theme.spacing(2),
        width: UI.input.width,
        minWidth: UI.input.minWidth,
        maxWidth: UI.input.maxWidth,
    },
}));

const Textfield = props => {
    const classes = useStyles();
    const {id, label, required, disabled, type, visibility, value, onChange, error, tip, placeholder} = props;
    const [text, setText] = useState(value || '');

    useEffect(() => {
        setText(value || '');
    }, [value]);

    const changeHandler = event => {
        setText(event.target.value);
        onChange(event);
    }

    const input = (
        <div>
            <TextField
                name={id}
                // fullWidth
                label={label || LABEL[id]}
                required={required}
                disabled={disabled}
                type={type || "text"}
                visibility={visibility}
                value={text}
                onChange={changeHandler}
                error={error}
                placeholder={placeholder}
                className={classes.input}
                InputLabelProps={{
                    shrink: true,
                }}
            />
        </div>
    );

    return tip ? <Tooltip tip={tip}>{input}</Tooltip> : input;
}

export default Textfield;
