import {createContext, useEffect, useReducer} from 'react';
import PropTypes from 'prop-types';

import axios, {setResponseInterceptors} from '../lib/axios';
import {STORE, URL} from '../config/constants';
import {formatEmployee} from '../store/actions';
import {getUser, setUser} from '../utils/utils';

const initialState = {
    isAuthenticated: false,
    isInitialized: false,
    user: null
};

const initialUser = {
    type: 'INITIALIZE',
    payload: {
        isAuthenticated: false,
        user: null
    }
}

const setAxiosToken = token => axios.defaults.headers.Authorization = `Bearer ${token}`;

const setSession = user => {
    if (user) {
        setUser(user);
        setAxiosToken(user.token);
    } else {
        sessionStorage.removeItem(STORE.user);
        delete axios.defaults.headers.Authorization;
    }
};

const handlers = {
    INITIALIZE: (state, action) => {
        const {isAuthenticated, user} = action.payload;
        return {
            ...state,
            isAuthenticated,
            isInitialized: true,
            user
        };
    },
    LOGIN: (state, action) => ({
        ...state,
        isAuthenticated: true,
        user: action.payload
    }),
    LOGOUT: state => ({
        ...state,
        isAuthenticated: false,
        user: null
    }),
    UPDATE: (state, action) => ({
        ...state,
        user: action.payload
    })
};

const reducer = (state, action) => (handlers[action.type]
    ? handlers[action.type](state, action)
    : state);

const AuthContext = createContext({
    ...initialState,
    platform: 'JWT',
    login: () => Promise.resolve(),
    logout: () => Promise.resolve(),
    resetPassword: () => Promise.resolve(),
    update: () => Promise.resolve(),
    updatePassword: () => Promise.resolve(),
    verifyOtp: () => Promise.resolve(),
    verifyToken: () => Promise.resolve()
});

export const AuthProvider = (props) => {
    const {children} = props;
    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        const initialize = async () => {
            try {
                const user = getUser();
                if (user) {
                    // console.log('[JWTContext.initialize] user.token:', user.token);
                    setAxiosToken(user.token);
                    setResponseInterceptors(dispatch);

                    dispatch({
                        type: 'INITIALIZE',
                        payload: {
                            isAuthenticated: !user.mfa,
                            user
                        }
                    });
                } else
                    dispatch(initialUser);
            } catch (err) {
                console.error(err);
                dispatch(initialUser);
            }
        };

        initialize();
    }, []);

    const login = async (username, password) => {
        const res = await axios.post(URL.api.login, {username, password});
        console.log('[JWTContent.login]:', res.data);

        const {user, token, expiresIn} = res.data;
        user.employee = formatEmployee(user.employee);
        const payload = {...user, token, expiresIn}

        setSession(payload);
        setResponseInterceptors(dispatch);
        if (!user.mfa)
            dispatch({type: 'LOGIN', payload});

        return !!user.mfa;
    };

    const logout = async () => {
        try { await axios(URL.api.logout); }
        catch (e) {}
        finally {
            setSession(null);
            dispatch({type: 'LOGOUT'});
        }
    };

    const update = employee => {
        const user = getUser();
        user.employee = employee;
        setUser(user);
        dispatch({type: 'UPDATE', payload: user});
    };

    const resetPassword = async username => {
        const res = await axios.post(URL.api.resetPwd, {username});
        console.log('[JWTContent.resetPassword]:', res);
    };

    const updatePassword = async (_id, password, isSelf, token) => {
        const url = isSelf ? URL.api.users : URL.api.resetPwd;
        const res = await axios.put(url + _id, {password, token});
        // console.log('[JWTContent.updatePassword] API response:', res.data);
    };

    const verifyOtp = async otp => {
        const payload = getUser();
        if(payload) {
            await axios.post(URL.api.verifyOtp, {otp});

            payload.mfa = false;
            setUser(payload);
            dispatch({type: 'LOGIN', payload});
        }
    }

    const verifyToken = async token =>
        await axios.post(URL.api.verifyToken, {token});

    return (
        <AuthContext.Provider
            value={{
                ...state,
                platform: 'JWT',
                login,
                logout,
                resetPassword,
                update,
                updatePassword,
                verifyOtp,
                verifyToken
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

AuthProvider.propTypes = {
    children: PropTypes.node.isRequired
};

export default AuthContext;
