import * as actionTypes from './actionTypes';
import {setLoading} from './common';
import axios from "../../lib/axios";
import {ID, URL} from '../../config/constants';

const setSnapshots = (dispatch, snapshots) => dispatch({type: actionTypes.SET_SNAPSHOTS, snapshots});

export const getSnapshots = async dispatch => {
    console.log('[getSnapshots] Start');
    setLoading(dispatch, true);
    
    try {
    const res = await axios.get(URL.api.snapshot);
    console.log('[getSnapshots] Response:', res.data);
    setSnapshots(dispatch, res.data);
    } catch (error) {
      console.log(error);
    }

    
     console.log('[getSnapshots] End');
};

const formatSnapshots = snapshots =>
    snapshots && snapshots.map(el => formatSnapshot(el));

const formatSnapshot = snapshot => {
    const formatted = {...snapshot};
    if(snapshot.site)
        formatted[ID.site] = snapshot.site._id;
    return formatted;
}