import { withStyles } from '@material-ui/core/styles';
import MuiTooltip from '@material-ui/core/Tooltip';

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0,0,0,0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}))(MuiTooltip);

const Tooltip = props => (
    <LightTooltip
        title={props.tip}
        placement={props.position ? props.position : 'top-end'}
    >
        {props.children}
    </LightTooltip>
);

export default Tooltip;
