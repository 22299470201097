import axios from 'axios';
import {URL} from '../config/constants';

const instance = axios.create({
    baseURL: URL.api.base,
    timeout: 20000,
});

instance.defaults.headers.post['Content-Type'] = 'application/json';

export const setResponseInterceptors = dispatch => {
    instance.interceptors.response.use(undefined, err => {
        err.response.status === 401 && dispatch({type: 'LOGOUT'});
        return Promise.reject(err);
    })
}

export default instance;
