import {createSlice} from '@reduxjs/toolkit';
import axios from '../lib/axios';
import {URL} from '../config/constants';
import {
    differenceInDays,
    endOfMonth,
    endOfWeek,
    format,
    startOfMonth,
    startOfWeek,
    subDays,
    subMonths,
    subWeeks
} from 'date-fns';

const initialState = {
    historyCount: [],
    workHours: [],
    events: []
};

const slice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        getWorkHours(state, action) {
            state.workHours = action.payload;
        },
        getHistoryCount(state, action) {
            state.historyCount = action.payload;
        }
    }
});

export const {reducer} = slice;

export const getWorkHours = () => async dispatch => {
    const res = await axios.get(URL.api.workHours);
    console.log('[dashboard.getWorkHours] API response:', res.data);
    dispatch(slice.actions.getWorkHours(formatWorkHours(res.data)));
};

export const getHistoryCount = () => async dispatch => {
    const res = await axios.get(URL.api.peopleCount.history);
    console.log('[dashboard.getHistoryCount] API response:', res.data);
    dispatch(slice.actions.getHistoryCount(formatHistoryCount(res.data)));
};

const formatHistoryCount = data => {
    const temp = [];

    if (data) {
        let start, end, tempDate;
        let content, title;
        const today = new Date()
        const yesterday = subDays(today, 1);

        title = format(yesterday, 'iii, dd MMM yyyy');
        content = {
            title,
            categories: data.hourly.categories.slice(6),
            series: data.hourly.series.map(el => ({
                name: el.name,
                data: el.data.slice(6)
            }))
        }
        temp.push(content);

        tempDate = subWeeks(yesterday, 1);
        title = 'Week ' + format(tempDate, 'w') + ', ' + format(yesterday, 'w');
        start = differenceInDays(today, startOfWeek(tempDate));
        content = genData(data.daily, start);
        temp.push({...content, title});

        title = format(yesterday, 'MMM yyyy');
        start = differenceInDays(today, startOfMonth(yesterday));
        content = genData(data.daily, start);
        temp.push({...content, title});

        tempDate = subMonths(yesterday, 1);
        title = format(tempDate, 'MMM yyyy');
        start = differenceInDays(today, startOfMonth(tempDate));
        end = differenceInDays(today, endOfMonth(tempDate));
        content = genData(data.daily, start, end);
        temp.push({...content, title});
        // console.log('[dashboard.formatHistoryCount] formatted data:', temp);
    }

    return temp;
}

const formatWorkHours = data => {
    const temp = [];

    if (data) {
        let start, end, tempDate;
        let content, title;
        const today = new Date()
        const yesterday = subDays(today, 1);

        title = 'Week ' + format(yesterday, 'w, yyyy');
        start = differenceInDays(today, startOfWeek(yesterday));
        content = genData(data, start);
        temp.push({...content, title});

        tempDate = subWeeks(yesterday, 1);
        title = 'Week ' + format(tempDate, 'w, yyyy');
        start = differenceInDays(today, startOfWeek(tempDate));
        end = differenceInDays(today, endOfWeek(tempDate));
        content = genData(data, start, end);
        temp.push({...content, title});

        title = format(yesterday, 'MMM yyyy');
        start = differenceInDays(today, startOfMonth(yesterday));
        content = genData(data, start);
        temp.push({...content, title});

        tempDate = subMonths(yesterday, 1);
        title = format(tempDate, 'MMM yyyy');
        start = differenceInDays(today, startOfMonth(tempDate));
        end = differenceInDays(today, endOfMonth(tempDate));
        content = genData(data, start, end);
        temp.push({...content, title});
        // console.log('[dashboard.formatWorkHours] formatted data:', temp);
    }

    return temp;
}

const genData = (data, start, end) => {
    const sId = -start;
    const eId = end ? -end : undefined;

    const series = data.series
        .filter(s => s.data.slice(sId, eId).some(e => e > 0))
        .map(s => ({
            name: s.name,
            data: s.data.slice(sId, eId)
        }));

    let categories = data.categories.slice(sId, eId);
    if (categories.length < 10) {
        let date = new Date();
        const year = date.getFullYear();
        const month = date.getMonth();

        categories = categories.map(c => {
            let [day, mth] = c.split('/');
            mth -= 1;
            date = new Date(month<mth?year-1:year, mth, day);

            return format(date,'eee dd/MM');
        })
    } else if (categories.length > 20)
        categories = categories.map(c => c.split('/')[0])

    return {categories, series}
}

export default slice;
