import * as actionTypes from './actionTypes';
import {setLoading} from './common';
import axios from "../../lib/axios";
import {URL} from '../../config/constants';

export const setAttendances = (dispatch, attendances) => dispatch({type: actionTypes.SET_ATTENDANCES, attendances});
export const updateAttendance = (dispatch, attendance) => dispatch({type: actionTypes.UPDATE_ATTENDANCE, attendance});
export const updatePage = (dispatch, page) => dispatch({type: actionTypes.UPDATE_PAGE, page});

export const getAttendances = async dispatch => {
    setLoading(dispatch, true);
    const res = await axios.get(URL.api.attendance);
    // console.log('[attendance.getAttendances] Response:', res.data);
    setAttendances(dispatch, res.data);
};

export const remarkAttendance = async (dispatch, employee, _id, remark) => {
    setLoading(dispatch, true);
    await axios.put(URL.api.attendance + _id, {remark});
    updateAttendance(dispatch, {_id, employee, remark});
};

export const correctedHours = async (dispatch, employee, _id, correctedHour) => {
	setLoading(dispatch, true);
	await axios.put(URL.api.attendance + '/correctedHour/' + _id, {correctedHour});
	updateAttendance(dispatch, {_id, employee, correctedHour});
};