import React, {useState} from 'react';
import { makeStyles } from "@material-ui/core/styles";
import _ from 'lodash';
import {MenuItem, Select} from '@material-ui/core';

import {BootstrapInput} from '.';

const useStyles = makeStyles(theme => ({
    margin: {
        margin: theme.spacing(1),
    },
}));

const DateRangeSelect = props => {
    const classes = useStyles();
    const {init, dateRange, onRangeChange} = props;

    return (
        <Select
            value={init || dateRange[0]}
            onChange={onRangeChange}
            input={<BootstrapInput />}
            className={classes.margin}
        >
            {dateRange.map((el, id) =>
                <MenuItem key={id} value={el}>{_.capitalize(el)}</MenuItem>
            )}
        </Select>
    );
}

export default DateRangeSelect;
