import * as Yup from 'yup';
import {Formik} from 'formik';
import {Box, FormHelperText} from '@material-ui/core';

import useAuth from '../../hooks/useAuth';
import useIsMountedRef from '../../hooks/useIsMountedRef';
import {FullWidthButton} from '../widgets/buttons';
import {Email} from '../widgets/input';
import {MSG} from '../../config/constants';

const PasswordRecovery = () => {
    const isMountedRef = useIsMountedRef(),
        {resetPassword} = useAuth();

    const validation = Yup.object().shape({
        email: Yup.string()
            .email('Must be a valid email')
            .max(255)
            .required('Email is required')
    });

    const onSubmit = async (values, {setErrors, setStatus, setSubmitting}) => {
        try {
            await resetPassword(values.email);
            if (isMountedRef.current) {
                setStatus({success: `Email sent to ${values.email}. Follow the link inside to reset your password.`});
                setErrors({submit: null});
                setSubmitting(false);
            }
        } catch (err) {
            console.error('[PasswordRecovery.onSubmit]', err);
            if (isMountedRef.current) {
                setStatus({success: false});
                setErrors({submit: MSG.fail.email});
                setSubmitting(false);
            }
        }
    };

    return (
        <Formik
            initialValues={{email: '', submit: null}}
            validationSchema={validation}
            onSubmit={onSubmit}
        >
            {({isValid, status, errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values}) => (
                <form noValidate onSubmit={handleSubmit}>
                    <Email
                        error={Boolean(touched.email && errors.email)}
                        helperText={touched.email && errors.email}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.email}
                    />
                    {status && status.success && (
                        <Box sx={{mt: 3}}>
                            <FormHelperText>{status.success}</FormHelperText>
                        </Box>
                    )}
                    {errors.submit && (
                        <Box sx={{mt: 3}}>
                            <FormHelperText error>{errors.submit}</FormHelperText>
                        </Box>
                    )}
                    <Box sx={{mt: 3}}>
                        <FullWidthButton disabled={!isValid || isSubmitting}>Recover Password</FullWidthButton>
                    </Box>
                </form>
            )}
        </Formik>
    );
};

export default PasswordRecovery;
