import {createRef, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import * as Yup from 'yup';
import {Formik} from 'formik';
import {Box, FormHelperText, InputAdornment} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import {Visibility, VisibilityOff} from '@material-ui/icons';
import ReCAPTCHA from "react-google-recaptcha";

import useAuth from '../../hooks/useAuth';
import useIsMountedRef from '../../hooks/useIsMountedRef';
import {FullWidthButton} from '../widgets/buttons';
import {Email, Password} from '../widgets/input';
import {UI, URL} from '../../config/constants';

const LoginJWT = (props) => {
    const isMountedRef = useIsMountedRef(),
        recaptchaRef = createRef(),
        navigate = useNavigate();

    const {login} = useAuth();
    const [showPassword, setShowPassword] = useState(false);

    const validator = Yup.object().shape({
        email: Yup.string()
            .email('Invalid email address')
            .max(64, 'Maximum 64 characters')
            .required('Username is required'),
        password: Yup.string()
            .min(UI.pwd.min, `Minimum ${UI.pwd.min} characters`)
            .max(UI.pwd.max, `Maximum ${UI.pwd.max} characters`)
            .required('Password is required')
    });

    const reCaptcha = (
        <Box
            sx={{mt: 2, mr: -14}}
            display="flex"
            justifyContent="flex-end"
            style={{
                transform: 'scale(0.6)',
                WebkitTransform: 'scale(0.6)',
                // transformOrigin: '0 0',
                // WebkitTransformOrigin: '0 0'
            }}>
            <ReCAPTCHA
                ref={recaptchaRef}
                size="invisible"
                badge="inline"
                sitekey={process.env.REACT_APP_RECAPTCHA_SITEKEY}
            />
        </Box>
    );

    const showPasswordHandler = () => {
        setShowPassword(prevState => !prevState);
    };

    const mouseDownPasswordHandler = event => {
        event.preventDefault();
    };

    const onSubmit = async (values, {setErrors, setStatus, setSubmitting}) => {
        try {
            const token = await recaptchaRef.current.executeAsync();
            if (!token)
                throw new Error('reCaptcha failed');

            const otpRequired = await login(values.email, values.password);
            if(otpRequired)
                navigate(URL.auth.base + URL.auth.otp);

            if (isMountedRef.current) {
                setStatus({success: true});
                setSubmitting(false);
            }
        } catch (err) {
            console.error('[LoginJTW.onSubmit]', err);
            if (isMountedRef.current) {
                setStatus({success: false});
                setErrors({submit: err.message});
                setSubmitting(false);
            }
        }
    };

    return (
        <Formik
            initialValues={{
                email: '',
                password: '',
                submit: null
            }}
            validationSchema={validator}
            onSubmit={onSubmit}
        >
            {({
                  isValid,
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  values
              }) => (
                <form
                    noValidate
                    onSubmit={handleSubmit}
                    {...props}
                >
                    <Email
                        error={Boolean(touched.email && errors.email)}
                        helperText={touched.email && errors.email}
                        label="Username"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.email}
                    />
                    <Password
                        error={Boolean(touched.password && errors.password)}
                        helperText={touched.password && errors.password}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type={showPassword ? 'text' : 'password'}
                        value={values.password}
                        InputProps={{
                            endAdornment:
                                <InputAdornment>
                                    <IconButton
                                        onClick={showPasswordHandler}
                                        onMouseDown={mouseDownPasswordHandler}
                                    >
                                        {showPassword ? <Visibility/> : <VisibilityOff/>}
                                    </IconButton>
                                </InputAdornment>
                        }}
                    />
                    {errors.submit && (
                        <Box sx={{mt: 3}}>
                            <FormHelperText error>
                                {errors.submit}
                            </FormHelperText>
                        </Box>
                    )}
                    <Box sx={{mt: 2}}>
                        <FullWidthButton
                            disabled={!isValid || isSubmitting}
                        >Log In</FullWidthButton>
                    </Box>
                    {reCaptcha}
                </form>
            )}
        </Formik>
    );
};

export default LoginJWT;
