import {format} from 'date-fns';
import {FORMAT, ID, STORE} from '../config/constants';

export const isRoleAllowed = (roleAllowed, user) => {
    let allowed = true;
    if (!user || !user.role || (roleAllowed && !roleAllowed.includes(user.role))) {
        allowed = false;
    }
    return allowed;
};

export const updateObject = (oldObj, newVal) => ({
    ...oldObj,
    ...newVal
});

export const isValidUrl = str => {
    let url;
    try {
        url = new URL(str);
    } catch (_) {
        return false;
    }
    return url.protocol === "http:" || url.protocol === "https:";
}

export const isDateInRange = (start, end, rowDate) => {
    let inRange = true, from, to;
    if (start) {
        from = format(start, FORMAT.date);
        if(rowDate < from)
            inRange = false;
    }
    if (end) {
        to = format(end, FORMAT.date);
        if(rowDate > to)
            inRange = false;
    }
    return inRange;
}

export const isDateInRangeEmitTime = (dateFrom, dateTo, dateValue) => {
	if (!dateFrom || !dateTo) return true;

	const value = new Date(dateValue);
	const from = new Date(dateFrom);
	const to = new Date(dateTo);

	from.setHours(0, 0, 0, 0);
	to.setHours(23, 59, 59, 999);
	value.setHours(0, 0, 0, 0);
	
/* 	console.log("dateFrom:"+dateFrom);
	console.log("dateTo:"+dateTo);
	
	console.log("value:"+value);
	console.log("dateFrom:"+dateFrom);
	
	console.log(value >= from && value <= to); */

	return value >= from && value <= to;
};

// convert date to DD/MM/YYYY format
export const toLocalDate = date => {
    let localDate = !!date
        ? new Date(date).toLocaleDateString()
        : new Date().toLocaleDateString();
    const parts = localDate.split('/');
    [parts[0], parts[1]] = [parts[1], parts[0]];
    return parts.join('/');
}

// convert date to YYYY-MM-DD format
export const toISODate = date => {
    let isoDate = '';
    if (date) {
        try {
            isoDate = new Date(date).toISOString().split('T')[0];
        } catch (e) {
        }
    }
    return isoDate;
}

// convert date from DD/MM/YYYY to YYYY-MM-DD format
export const toISODate2 = date => {
    let isoDate = '';
    if (date && date.includes('/')) {
        const parts = date.split('/');
        [parts[0], parts[2]] = [parts[2], parts[0]];
        parts[1] = parts[1].padStart(2, '0');
        parts[2] = parts[2].padStart(2, '0');
        isoDate = parts.join('-');
    }
    return isoDate;
}

// convert date from YYYY-MM-DD to DD/MM/YYYY format
export const toLocalDate2 = date => {
    let localDate = '';
    if (date && date.includes('-')) {
        const parts = date.split('-');
        [parts[0], parts[2]] = [parts[2], parts[0]];
        parts[1] = parts[1].padStart(2, '0');
        parts[2] = parts[2].padStart(2, '0');
        localDate = parts.join('/');
    }
    return localDate;
}

// convert date from DD/MM/YYYY to milliseconds since Unix Epoch.
export const getMillisecondes = date => {
    const parts = date.split('/');
    [parts[0], parts[2]] = [parts[2], parts[0]];
    const newDate = new Date(parts.join('-')).getTime();
    return newDate;
}

// get date from YYYY-MM-DD string
export const getDate = ymd => {
    const [year, month, day] = ymd.split('-');
    return new Date(year, month-1, day);
}

export const checkValidity = (rules, value) => {
    let result = true;
    let patt = rules.pattern;

    if (patt) {
        result = patt.test(value) && result;
    }
    if (rules.required) {
        result = !!value && result;
        if (Array.isArray(value) && !value.length) {
            result = false;
        }
    }
    if (rules.minLength) {
        result = (!value || value.length >= rules.minLength) && result;
    }
    if (rules.maxLength) {
        result = value.length <= rules.maxLength && result;
    }
    if (rules.lowercase) {
        patt = /[a-z]/g;
        result = patt.test(value) && result;
    }
    if (rules.uppercase) {
        patt = /[A-Z]/g;
        result = patt.test(value) && result;
    }

    return result;
}

export const updateValidity = (prev, valid) => {
    Object.keys(prev).forEach(key => {
        if(prev[key].valid !== undefined) {
            prev[key].valid = valid;
        }
    });
    return prev;
}

export const isObjectValid = obj => !!obj && typeof obj === 'object' && Object.keys(obj).length > 0;

export const capitalize = s => s && s[0].toUpperCase() + s.slice(1);

export const getName = obj => {
    let name = '';
    if (obj) {
        name = obj.first;
        if (obj.middle) {
            name += ' ' + obj.middle;
        }
        if (obj.last) {
            name += ' ' + obj.last;
        }
    }
    return name;
}

export const getName2 = obj => {
    let name = '';
    if (obj) {
        name = obj[ID.fName];
        if (obj[ID.lName]) {
            name += ' ' + obj[ID.lName];
        }
    }
    return name;
}

export const showSnackbar = (func, msg, type, horizontal, vertical) =>
    func( msg || 'Changes saved', {
        anchorOrigin: {
            horizontal: horizontal || 'right',
            vertical: vertical || 'top'
        },
        variant: type || 'success'
    });

export const getUser = () => JSON.parse(sessionStorage.getItem(STORE.user));
export const setUser = user => sessionStorage.setItem(STORE.user, JSON.stringify(user));

