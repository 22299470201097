import { subDays, subHours, subMinutes, subSeconds } from 'date-fns';
import { mock } from '../lib/axiosMock';

mock
  .onGet('/api/attendances')
  .reply(() => {
    const attendances = [
      {
        id: '5e887ac47eed253091be10cb',
        photo: '/static/mock-images/avatars/avatar-carson_darrin.png',
        name: 'Carson Darrin',
        department: 'Admin',
        date: '2021-04-20',
        firstCheckIn: '08:30',
        lastCheckOut: '18:30',
        inOfficeHours: '10',
        lateInMin: '',
        lateInReason: '',
        earlyOutMin:'',
        earlyOutReason:'',
        fullAttendance: 'Yes',
        remark: ''
      },
      {
        id: '5e887b209c28ac3dd97f6db5',
        photo: '/static/mock-images/avatars/avatar-fran_perez.png',
        name: 'Fran Perez',
        department: 'Admin',
        date: '2021-04-22',
        firstCheckIn: '08:30',
        lastCheckOut: '18:30',
        inOfficeHours: '10',
        lateInMin: '',
        lateInReason: '',
        earlyOutMin:'',
        earlyOutReason:'',
        fullAttendance: 'Yes',
        remark: ''
      },
      {
        id: '5e887b7602bdbc4dbb234b27',
        photo: '/static/mock-images/avatars/avatar-jie_yan_song.png',
        name: 'Jie Yan Song',
        department: 'BD',
        date: '2021-04-23',
        firstCheckIn: '10:30',
        lastCheckOut: '18:30',
        inOfficeHours: '10',
        lateInMin: '30',
        lateInReason: 'Meet THK customer',
        earlyOutMin:'',
        earlyOutReason:'',
        fullAttendance: 'No',
        remark: ''
      },
      {
        id: '5e86809283e28b96d2d38537',
        photo: '/static/mock-images/avatars/avatar-jane_rotanson.png',
        name: 'Jane Rotanson',
        department: 'BD',
        date: '2021-04-27',
        firstCheckIn: '09:00',
        lastCheckOut: '15:30',
        inOfficeHours: '6.5',
        lateInMin: '',
        lateInReason: '',
        earlyOutMin:'180',
        earlyOutReason:'Site survay',
        fullAttendance: 'No',
        remark: ''
      },
      {
        id: '5e86805e2bafd54f66cc95c3',
        photo: '/static/mock-images/avatars/avatar-miron_vitold.png',
        name: 'Miron Vitold',
        department: 'AI',
        date: '2021-04-27',
        firstCheckIn: '08:30',
        lastCheckOut: '18:30',
        inOfficeHours: '10',
        lateInMin: '',
        lateInReason: '',
        earlyOutMin:'',
        earlyOutReason:'',
        fullAttendance: 'Yes',
        remark: ''
      },
      {
        id: '5e887a1fbefd7938eea9c981',
        photo: '/static/mock-images/avatars/avatar-penjani_inyene.png',
        name: 'Penjani Inyene',
        department: 'AI',
        date: '2021-04-27',
        firstCheckIn: '08:30',
        lastCheckOut: '18:30',
        inOfficeHours: '10',
        lateInMin: '',
        lateInReason: '',
        earlyOutMin:'',
        earlyOutReason:'',
        fullAttendance: 'Yes',
        remark: ''
      },
      {
        id: '5e887d0b3d090c1b8f162003',
        photo: '/static/mock-images/avatars/avatar-omar_darobe.png',
        name: 'Omar Darobe',
        department: 'AI',
        date: '2021-04-27',
        firstCheckIn: '08:30',
        lastCheckOut: '18:30',
        inOfficeHours: '10',
        lateInMin: '',
        lateInReason: '',
        earlyOutMin:'',
        earlyOutReason:'',
        fullAttendance: 'Yes',
        remark: ''
      },
      {
        id: '5e88792be2d4cfb4bf0971d9',
        photo: '/static/mock-images/avatars/avatar-siegbert_gottfried.png',
        name: 'Siegbert Gottfried',
        department: 'AI',
        date: '2021-04-27',
        firstCheckIn: '08:30',
        lastCheckOut: '18:30',
        inOfficeHours: '10',
        lateInMin: '',
        lateInReason: '',
        earlyOutMin:'',
        earlyOutReason:'',
        fullAttendance: 'Yes',
        remark: ''
      },
      {
        id: '5e8877da9a65442b11551975',
        photo: '/static/mock-images/avatars/avatar-iulia_albu.png',
        name: 'Iulia Albu',
        department: 'AI',
        date: '2021-04-27',
        firstCheckIn: '08:30',
        lastCheckOut: '18:30',
        inOfficeHours: '10',
        lateInMin: '',
        lateInReason: '',
        earlyOutMin:'',
        earlyOutReason:'',
        fullAttendance: 'Yes',
        remark: ''
      },
      {
        id: '5e8680e60cba5019c5ca6fda',
        photo: '/static/mock-images/avatars/avatar-nasimiyu_danai.png',
        name: 'Nasimiyu Danai',
        department: 'AI',
        date: '2021-04-27',
        firstCheckIn: '08:30',
        lastCheckOut: '18:30',
        inOfficeHours: '10',
        lateInMin: '',
        lateInReason: '',
        earlyOutMin:'',
        earlyOutReason:'',
        fullAttendance: 'Yes',
        remark: ''
      }
    ];

    return [200, { attendances }];
  });
