import { mock } from '../lib/axiosMock';

mock
  .onGet('/api/accessControl')
  .reply(() => {
    const accessControl = [
      {
        id: '5e887ac47eed253091be10aa',
        accessStrategy: 'Daily',
        active: true,
        startDate: '2021-03-01',
        endDate: '2021-03-31',
        startTime: '08:00',
        endTime: '20:00',
        target: ['Human Resource'],
        devices: ['Main door', 'Side door']
      },
      {
        id: '5e887ac47eed253091be10ab',
        accessStrategy: 'Weekdays',
        active: false,
        startDate: '2021-04-01',
        endDate: '2021-04-30',
        startTime: '12:00',
        endTime: '22:00',
        target: ['Business Development'],
        devices: ['Main door',  'Side door',  'Back door',  'Store room']
      },
      {
        id: '5e887ac47eed253091be10ac',
        accessStrategy: 'Weekend',
        active: true,
        startDate: '2020-01-01',
        endDate: '2022-12-31',
        startTime: '08:00',
        endTime: '20:00',
        target: ['Support','AI'],
        devices: ['Main door','Side door','Back door']
      },
      {
        id: '5e887ac47eed253091be10ad',
        accessStrategy: 'Daily',
        active: false,
        startDate: '2018-01-01',
        endDate: '2025-12-31',
        startTime: '00:00',
        endTime: '23:59',
        target: ['IT & Network Software', 'Human Resource'],
        devices: ['Main door', 'Side door', 'Server room']
      },
      {
        id: '5e887ac47eed253091be10ae',
        accessStrategy: 'One Time',
        active: true,
        startDate: '2021-05-30',
        endDate: '2021-05-30',
        startTime: '12:00',
        endTime: '23:59',
        target: ['Business Development'],
        devices: ['Main door', 'Side door', 'Store room']
      }
    ];

    return [200, { accessControl }];
  });
