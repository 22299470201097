import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import MuiRadio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {capitalize} from '../../../utils/utils';

const useStyles = makeStyles(theme => ({
    radio: {
        marginLeft: theme.spacing(1),
        // paddingTop: theme.spacing(1),
        // minWidth: 60
    }
}));

const Radio = props => {
    const classes = useStyles();
    return (
        <FormControlLabel
            value={props.text}
            control={<MuiRadio color="primary" />}
            label={capitalize(props.text)}
            labelPlacement="end"
            className={classes.radio}
        />
    );
}

export default Radio;
