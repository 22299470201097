import * as actionTypes from "./actionTypes";
import { setLoading, setError } from "./common";
import axios from "../../lib/axios";
import { ID, OPTIONS, STORE, URL, ERROR } from "../../config/constants";
import { AllInboxOutlined } from "@material-ui/icons";
import Context from "../../contexts/SettingsContext";
import { useContext } from "react";

const addExitGrp = (dispatch, exitGrp) =>
  dispatch({ type: actionTypes.ADD_EXIT_GROUP, exitGrp });
const setExitGrp = (dispatch, exitGrps) =>
  dispatch({ type: actionTypes.SET_EXIT_GROUP, exitGrps });
const editExitGrp = (dispatch, exitGrp) =>
  dispatch({ type: actionTypes.UPDATE_EXIT_GROUP, exitGrp });
const removeExitGrp = (dispatch, exitGrp) =>
  dispatch({ type: actionTypes.DELETE_EXIT_GROUP, exitGrp });

export const createExitGrp = async (dispatch, exitGrp) => {
  setLoading(dispatch, true);
  console.log("[exit.createExitGrp] exitGrp: ", exitGrp);
  try {
    const res = await axios.post(URL.api.exit.add, exitGrp);
    setLoading(dispatch, false);
  } catch (e) {
    setError(dispatch, ERROR.createExitGroup);
    throw Error(ERROR.createExitGroup.msg);
  }
};

export const getExitGrps = async (dispatch, tId) => {
  setLoading(dispatch, true);

  const getUrl = URL.api.exit.getGrps + "/" + tId;
  console.log("[exit.getExitGrp] getUrl: ", getUrl);
  try {
    const res = await axios.get(getUrl);
    console.log("[exit.getExitGrp] ResponseData: ", res.data);
    console.log("[exit.getExitGrp] Response:", res);
    setExitGrp(dispatch, res.data);
  } catch (e) {
    setError(dispatch, ERROR.getExitGroups);
    throw Error(ERROR.getExitGroups.msg);
  }
};

export const deleteExitGrp = async (dispatch, exitGrp) => {
  setLoading(dispatch, true);
  const deleteUrl = URL.api.exit.deleteGrp + exitGrp._id;
  try {
    console.log("[exit.deleteExitGrp] deleteUrl:", deleteUrl);
    console.log("[exit.deleteExitGrp] exitGrp:", exitGrp);
    const res = await axios.delete(deleteUrl);

    console.log("[exit.deleteExitGrp] res:", res);
    // removeExitGrp(dispatch, exitGrp);
  } catch (e) {
    setError(dispatch, ERROR.deleteExitGroup);
    console.error("[exit.deleteExitGrp] Error", e);
    throw Error(ERROR.deleteExitGroup.msg);
  }
};

export const updateExitGrp = async (dispatch, exitGrp) => {
  //? Send Tenant id as well?
  setLoading(dispatch, true);
  const updateUrl = URL.api.exit.updateGrp;
  console.log("[exit.updateExitGrp] exitGrp", exitGrp);
  console.log("[exit.updateExitGrp] exitGrp.id", exitGrp._id);
  console.log("[exit.updateExitGrp] URL", updateUrl);
  try {
    await axios.put(updateUrl, exitGrp);
    // editExitGrp(dispatch, exitGrp);
  } catch (e) {
    setError(dispatch, ERROR.updateExitGroup);
    console.error("[exit.updateExitGrp] Error", e);
    throw Error(ERROR.updateExitGroup.msg);
  }
};

const formatForUI = (data) => {};
