import {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {InputLabel, MenuItem, FormControl, OutlinedInput, Select} from '@material-ui/core';
import {LABEL, UI} from '../../../config/constants';
import {Tooltip} from '.';

const useStyles = makeStyles(theme => ({
    formControl: {
        margin: theme.spacing(2),
        width: UI.input.width,
        minWidth: UI.input.minWidth,
        maxWidth: UI.input.maxWidth,
    },
}));

const AutocompSelect = props => {
    const classes = useStyles();
    const {id, value, options, required, disabled, onChange, tip} = props;
    const label = LABEL[id];
    const [selected, setSelected] = useState('');

    useEffect(() => {
        setSelected(value || '');
    }, [value]);

    const changeHandler = event => {
        setSelected(event.target.value);
        onChange && onChange(event);
    }

    const body = (
        <FormControl
            required={required}
            disabled={disabled}
            className={classes.formControl}
        >
            <InputLabel shrink>{label}</InputLabel>
            <Select
                fullWidth
                id={id}
                name={id}
                value={selected}
                onChange={changeHandler}
                input={<OutlinedInput notched label={label}/>}
            >
                {options
                    ? options.map((option, i) => <MenuItem key={i} value={option.value}>{option.text}</MenuItem>)
                    : null}
            </Select>
        </FormControl>
    );

    return (
        <div>
            {tip ? <Tooltip tip={tip}>{body}</Tooltip> : body}
        </div>
    );
}

export default AutocompSelect;
