import {useEffect} from 'react';
import {useRoutes} from 'react-router-dom';
import {SnackbarProvider} from 'notistack';
import {ThemeProvider} from '@material-ui/core';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import {useIdleTimer} from 'react-idle-timer';

import GlobalStyles from './components/GlobalStyles';
import SettingsDrawer from './components/SettingsDrawer';
import SplashScreen from './components/SplashScreen';
import {gtmConfig} from './config/config';
import useAuth from './hooks/useAuth';
import useScrollReset from './hooks/useScrollReset';
import useSettings from './hooks/useSettings';
import gtm from './lib/gtm';
import routes from './routes';
import {createTheme} from './theme';
import {getUser} from './utils/utils';
import {PARAM} from './config/constants';

const App = () => {
    const content = useRoutes(routes);
    const {settings} = useSettings();
    const auth = useAuth();
    useScrollReset();

    useEffect(() => {
        gtm.initialize(gtmConfig);
    }, []);

    const theme = createTheme({
        direction: settings.direction,
        responsiveFontSizes: settings.responsiveFontSizes,
        roundedCorners: settings.roundedCorners,
        theme: settings.theme
    });

    const onIdle = () => {
        const user = getUser();
        // const now = new Date().toLocaleTimeString();
        // console.log('[App.onIdle]', now, user);
        user && user.tenantType
        && user.tenantType.endsWith(PARAM.tenantOtpMark)
        && auth.logout();
    }
    useIdleTimer({
        timeout: PARAM.sessionTimeout,
        onIdle,
        crossTab: {emitOnAllTabs: true}
    })

    return (
        <ThemeProvider theme={theme}>
            <SnackbarProvider dense maxSnack={3}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <GlobalStyles/>
                    <SettingsDrawer/>
                    {auth.isInitialized ? content : <SplashScreen/>}
                </MuiPickersUtilsProvider>
            </SnackbarProvider>
        </ThemeProvider>
    );
};

export default App;
