import {Box, Button} from '@material-ui/core';

const ButtonRight = props => {
    const {onClick, label, disabled} = props
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                p: 2
            }}
        >
            <Button
                color="primary"
                type="submit"
                variant="contained"
                disabled={disabled}
                onClick={onClick}
            >
                {label || 'Save Settings'}
            </Button>
        </Box>
    );
}

export default ButtonRight;
