export const THEMES = {
  LIGHT: "LIGHT",
  DARK: "DARK",
  NATURE: "NATURE",
};

export const URL = {
  api: {
    //base: "http://localhost:3010/v1/",
    // base: "http://192.168.1.16:3010/v1/",
    // base: "http://192.168.1.16:8001/",
    base: 'https://api.pensees-systems.com/',
    login: "/users/login",
    logout: "/users/logout",
    resetPwd: "/users/resetPwd/",
    verifyOtp: "/users/verifyOtp",
    verifyToken: "/users/verifyToken",
    users: "/users/",
    employees: "/employees/",
    photo: "/employees/photo",
    sync: "/employees/sync",
    department: "/departments/",
    snapshot: "/snapshots/",
    device: "/devices/",
    deviceSync: "/devices/sync",
    openDoor: "/devices/opendoor/",
    attendance: "/attendance/",
    peopleCount: {
      live: "/attendance/live",
      history: "/attendance/history?type=",
    },
    telegram: "/telegram/",
    workHours: "/attendance/hours",
    notifications: "/notifications",
    exit: {
      add: "/exit/add",
      getGrps: "/exit/groups",
      updateGrp: "/exit/update",
      deleteGrp: "/exit/delete/",
    },
  },
  auth: {
    base: "/auth",
    login: "/login",
    logout: "/logout",
    forgotPwd: "/forgotPassword",
    activateAcct: "/activate/:token",
    otp: "/otp",
  },
  account: "/account",
  attendance: {
    base: "/attendances",
    accessCtl: "/accessControl",
    detailedReport: "/detailedReport",
  },
  shifts: {
    manage: "/manage",
    report: "/report",
  },
  exit: {
    manage: "/manageExit",
  },
  emp: {
    base: "/employees",
    new: "/new",
    info: "/:empID",
    dept: "/departments",
    org: "/orgChart",
  },
  device: "/devices",
  snapshot: "/snapshots",
  rmacess: {
    base: "/remote",
    remoteSn: "/:sn",
  },
  telegram: "/telegram",
  leave: {
    base: "/leave",
    approve: "/approve",
    setup: "/settings",
  },
  expense: {
    base: "/expense",
    approve: "/approve",
  },
};

export const STORE = {
  user: "user",
};

export const LABEL = {
  accessStrategy: "Access Strategy",
  active: "Active",
  action: "Action",
  addr1: "Address 1",
  addr2: "Address 2",
  chanId: "Telegram Channel ID",
  botId: "Telebot ID",
  cert: "Qualification / Category",
  company: "Company",
  companyId: "Company",
  contact: "Contact No.",
  dateOfBirth: "Date Of Birth",
  dateOfJoin: "Date Of Join",
  dept: "Department",
  deptId: "Department",
  devices: "Devices",
  edit: "Edit",
  email: "Email",
  empID: "Employee ID",
  endDate: "EndDate",
  endTime: "EndTime",
  firstName: "First Name",
  gender: "Gender",
  ic: "IC No.",
  jobNo: "Job No.",
  lastName: "Last Name",
  loc: "Location",
  manager: "Manager",
  marital: "Married Status",
  name: "Name",
  nationality: "Nationality",
  occupation: "Occupation",
  online: "Status",
  parent: "Parent",
  postal: "Postcode",
  race: "Race",
  remark: "Remark",
  correctedHour: 'Corrected Hours',
  role: "Role",
  site: "Site",
  sn: "SN",
  startDate: "Start Date",
  startTime: "Start Time",
  supervisor: "Supervisor",
  target: "Target",
  type: "Type",
  shiftName: "Name",
  shiftStart: "Start",
  shiftEnd: "End",
  shiftRecurr: "Recurrence",
  shiftDays: "Days",
  shiftReset: "Reset",
  attdGroup: "Attendance Group",
  deviceGroup: "Device Group",
  exitName: "Name",
  exitOutTime: "Out Time",
  exitInTime: "In Time",
  cutOffTime: "Cut Off",
  exitReset: "Reset",
  exitUsers: "Users",
};

export const ID = {
  _id: "_id",
  accessStrategy: "accessStrategy",
  account: "account",
  active: "active",
  addr1: "addr1",
  addr2: "addr2",
  admin: "admin",
  ai: "ai",
  chanId: "chanId",
  botId: "botId",
  cert: "cert",
  company: "company",
  companyId: "companyId",
  contact: "contact",
  bd: "bd",
  date: "date",
  dept: "dept",
  deptMgr: "deptMgr",
  deptId: "deptId",
  devices: "devices",
  dob: "dateOfBirth",
  doj: "dateOfJoin",
  earlyMin: "earlyMin",
  email: "email",
  empId: "empId",
  endDate: "endDate",
  endTime: "endTime",
  fName: "firstName",
  fullAttend: "fullAttend",
  gender: "gender",
  hr: "hr",
  hours: "hours",
  ic: "ic",
  id: "id",
  in: "in",
  jobNo: "jobNo",
  lateMin: "lateMin",
  loc: "location",
  lName: "lastName",
  manager: "manager",
  marital: "marital",
  mId: "mId",
  mName: "mName",
  mPhoto: "mPhoto",
  name: "name",
  nationality: "nationality",
  online: "online",
  out: "out",
  parent: "parent",
  photo: "photo",
  pId: "pId",
  pName: "pName",
  pos: "occupation",
  postal: "postal",
  race: "race",
  role: "role",
  remark: "remark",
  correctedHour: 'correctedHour',
  site: "site",
  siteName: "siteName",
  sn: "sn",
  startDate: "startDate",
  startTime: "startTime",
  supervisor: "supervisor",
  target: "target",
  tenantId: "tenantId",
  time: 'time',
  type: "type",
  typeId: "typeId",
  user: "user",
  shiftName: "Name",
  shiftStart: "Start",
  shiftEnd: "End",
  shiftRecurr: "Recurrence",
  shiftDays: "Days",
  shiftReset: "Reset",
  attdGroup: "AttendanceGroup",
  deviceGroup: "DeviceGroup",
  exitName: "exitName",
  exitOutTime: "exitOutTime",
  exitInTime: "exitInTime",
  cutOffTime: "cutOffTime",
  exitReset: "exitReset",
  exitUsers: "exitUsers",
};

export const OPTIONS = {
  accessStrategy: ["One Time", "Daily", "Weekdays", "Weekend"],
  gender: ["male", "female"],
  historyRange: ["daily", "weekly", "this month", "last month"],
  marital: ["Single", "Married", "Divorced", "Widowed"],
  nationality: [
    "Afghan",
    "Albanian",
    "Algerian",
    "American",
    "Argentine",
    "Argentinian",
    "Australian",
    "Austrian",
    "Bangladeshi",
    "Batswana",
    "Belgian",
    "Bolivian",
    "Brazilian",
    "British",
    "Bulgarian",
    "Cambodian",
    "Cameroonian",
    "Canadian",
    "Chilean",
    "Chinese",
    "Colombian",
    "Costa Rican",
    "Croatian",
    "Cuban",
    "Czech",
    "Danish",
    "Dominican",
    "Dutch",
    "Ecuadorian",
    "Egyptian",
    "Emirati",
    "English",
    "Estonian",
    "Ethiopian",
    "Fijian",
    "Finnish",
    "French",
    "German",
    "Ghanaian",
    "Greek",
    "Guatemalan",
    "Haitian",
    "Honduran",
    "Hungarian",
    "Icelandic",
    "Indian",
    "Indonesian",
    "Iranian",
    "Iraqi",
    "Irish",
    "Israeli",
    "Italian",
    "Jamaican",
    "Japanese",
    "Jordanian",
    "Kenyan",
    "Korean",
    "Kuwaiti",
    "Lao",
    "Latvian",
    "Lebanese",
    "Libyan",
    "Lithuanian",
    "Malagasy",
    "Malaysian",
    "Malian",
    "Maltese",
    "Mexican",
    "Mongolian",
    "Moroccan",
    "Mozambican",
    "Namibian",
    "Nepalese",
    "New Zealand",
    "Nicaraguan",
    "Nigerian",
    "Norwegian",
    "Pakistani",
    "Panamanian",
    "Paraguayan",
    "Peruvian",
    "Philippine",
    "Polish",
    "Portuguese",
    "Romanian",
    "Russian",
    "Salvadorian",
    "Saudi",
    "Scottish",
    "Senegalese",
    "Serbian",
    "Singaporean",
    "Slovak",
    "South African",
    "Spanish",
    "Sri Lankan",
    "Sudanese",
    "Swedish",
    "Swiss",
    "Syrian",
    "Taiwanese",
    "Tajikistan",
    "Thai",
    "Tongan",
    "Tunisian",
    "Turkish",
    "Ukrainian",
    "Uruguayan",
    "Venezuelan",
    "Vietnamese",
    "Welsh",
    "Zambian",
    "Zimbabwean",
  ],
  race: ["Chinese", "Malay", "Indian", "Eurasian", "Others"],
  role: [
    {
      value: ID.user,
      text: "Normal user",
    },
    {
      value: ID.manager,
      text: "Super user",
    },
    {
      value: ID.admin,
      text: "Administrator",
    },
  ],
  companyType: ["C1", "C2", "C3"], // C1: construction
  deptType: { C: "Company", S: "Site", D: "Department" },
  deviceType: { in: "In", out: "Out" },
  target: [
    "Human Resource",
    "Business Development",
    "AI",
    "Support",
    "IT & Network Software",
  ],
  exitUsers: ["No employees found."],
  workHours: ["this week", "last week", "this month", "last month"],
};

export const UI = {
  input: {
    width: "100%",
    minWidth: 160,
    maxWidth: 600,
  },
  accTabs: [
    { label: "General", value: "general" },
    { label: "Employment", value: "employment" },
    // {label: 'Notifications', value: 'notifications'},
    { label: "Security", value: "security" },
  ],
  bodyBox: {
    backgroundColor: "background.default",
    minHeight: "100%",
    py: 4,
  },
  pwd: {
    min: 6,
    max: 32,
  },
  otpLength: 5,
};

export const MSG = {
  success: {
    attRmk: "Add remark successfully",
    attCorrHrs: 'Add corrected hour successfully',
    deptAdd: "Department added successfully",
    deptDel: "Department deleted successfully",
    deptUpd: "Department updated successfully",
    deptSync: "Departments synchronized successfully",
    deviceSync: "Devices synchronized successfully",
    deviceUpd: "Device updated successfully",
    empAdd: "Employee created successfully",
    empDel: "Employee deleted successfully",
    empUpd: "Employee updated successfully",
    empSync: "Employees synchronized successfully",
    openDoor: "Open door successfully",
    teleAdd: "Telegram group created successfully",
    teleDel: "Telegram group deleted successfully",
    teleUpd: "Telegram group updated successfully",
    teleTest: "Telegram group test successfully",
    pwd: "Password updated. Please re-login",
  },
  fail: {
    attRmk: "Adding remark failed",
    attCorrHrs: 'Adding corrected hour failed',
    deptAdd: "Adding department failed",
    deptDel: "Deleting department failed",
    deptUpd: "Updating department failed",
    deptSync: "Synchronizing departments failed",
    deptName: "Empty or duplicated name",
    deptInvalid: "Invalid or unchanged data",
    deptParent: "Parent department is required",
    deviceSync: "Synchronizing devices failed",
    deviceUpd: "Updating device failed",
    email: "Invalid email",
    empAdd: "Adding employee failed",
    empDel: "Deleting employee failed",
    empPhoto: "Uploading photo failed",
    empUpd: "Updating employee failed",
    empSync: "Synchronizing employees failed",
    pwd: "Changing password failed",
    openDoor: "Open door failed. Please retry",
    teleAdd: "Adding telegram group failed",
    teleDel: "Deleting telegram group failed",
    teleUpd: "Updating telegram group failed",
    teleTest: "Test telegram group failed",
    otp: "Invalid code. Please retry",
  },
};

export const ACTION = {
  addEmployee: "ADD_EMPLOYEES",
  deleteEmployee: "DELETE_EMPLOYEES",
  getAttendances: "GET_ATTENDANCES",
  getDepartments: "GET_DEPARTMENTS",
  getEmployees: "GET_EMPLOYEES",
  login: "LOGIN",
  updateAttendance: "UPDATE_ATTENDANCE",
  updateEmployee: "UPDATE_EMPLOYEES",
};

export const ERROR = {
  login: {
    code: ACTION.login,
    msg: "Login failed. Please try again.",
  },
  getAttendances: {
    code: ACTION.getAttendances,
    msg: "Retrieve attendances failed. Please try again.",
  },
  updateAttendance: {
    code: ACTION.updateAttendance,
    msg: "Update attendance failed. Please try again.",
  },
  getEmployees: {
    code: ACTION.getEmployees,
    msg: "Retrieve employees failed. Please try again.",
  },
  addEmployee: {
    code: ACTION.addEmployee,
    msg: "Add form failed. Please try again.",
  },
  updateEmployee: {
    code: ACTION.updateEmployee,
    msg: "Update form failed. Please try again.",
  },
  deleteEmployee: {
    code: ACTION.deleteEmployee,
    msg: "Delete form failed. Please try again.",
  },
  getDepartments: {
    code: ACTION.getDepartments,
    msg: "Retrieve departments failed. Please try again.",
  },
  accessDenied: "Access is denied.",
  createExitGroup: {
    code: ACTION.addExitGroup,
    msg: "Failed to submit new group data to database.",
  },
  getExitGroups: {
    code: ACTION.getExitGroup,
    msg: "Failed to retrive exit groups data from database.",
  },
  deleteExitGroup: {
    code: ACTION.deleteExitGroup,
    msg: "Failed to delete exit group data from database.",
  },
  updateExitGroup: {
    code: ACTION.updateExitGroup,
    msg: "Failed to update exit group.",
  },
};

export const FORMAT = {
  date: "yyyy-MM-dd",
  time: "HH:mm:ss",
};

export const PARAM = {
  sessionTimeout: 1000 * 60 * 15, // Timeout in milliseconds
  tenantOtpMark: "O", // Tenant OTP mark in department type
};
