import React, {useEffect, useState} from "react";
import MuiCheckbox from '@material-ui/core/Checkbox';

const Checkbox = props => {
    const { rowId, id, checked, onChange, disabled } = props;
    const [selected, setSelected] = useState(!!checked);

    useEffect(() => {
        setSelected(!!checked);
    }, [checked]);

    const changeHandler = event => {
        setSelected(event.target.checked);
        onChange && onChange(event, rowId);
    }

    return (
        <MuiCheckbox
            name={id}
            checked={selected}
            onChange={changeHandler}
            defaultValue={selected}
            disabled={disabled}
            color="primary"
        />
    );
}

export default Checkbox;
