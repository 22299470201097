import * as actionTypes from "./actions/actionTypes";
import { updateObject } from "../utils/utils";
import { ID } from "../config/constants";

const initState = {
  loading: false,
  error: null,
};

const addUsers = (state, action) => {
  let users = [action.user];
  if (state.users) users = state.users.concat(users);

  return updateObject(state, { ...initState, users });
};

const updateUser = (state, action) => {
  let user = { ...state.user };
  const users = [...state.users];
  const id = users.findIndex((el) => el[ID.id] === action.user[ID.id]);

  if (action.type === actionTypes.UPDATE_USERS) {
    users[id] = updateObject(state.users[id], action.user);
    if (user.id === action.user.id) {
      user = action.user;
    }
  } else {
    users.splice(id, 1);
  }

  return updateObject(state, { ...initState, users });
};

const updatePage = (state, action) =>
  updateObject(state, {
    page: updateObject(state.page, action.page),
  });

const addEmployee = (state, action) => {
  const newEmp = { ...action.employee };

  // Get Company & Department name
  if (state.departments) {
    let dept;
    if (newEmp[ID.companyId]) {
      dept = state.departments.find((el) => el[ID.id] === newEmp[ID.companyId]);
      newEmp[ID.company] = dept.name;
    }
    if (newEmp[ID.deptId]) {
      dept = state.departments.find((el) => el[ID.id] === newEmp[ID.deptId]);
      newEmp[ID.dept] = dept.name;
    }
  }

  const employees = state.employees ? state.employees.concat(newEmp) : [newEmp];
  return updateObject(state, {
    ...initState,
    employees,
  });
};

const updateEmployee = (state, action) => {
  if (!state.employees) return state;

  const employees = [...state.employees];
  const id = employees.findIndex((el) => el.id === action.employee.id);

  if (action.type === actionTypes.UPDATE_EMPLOYEE) {
    employees[id] = { ...employees[id], ...action.employee };
  } else {
    employees.splice(id, 1);
  }

  return updateObject(state, { ...initState, employees });
};
const updateExitGroup = (state, action) => {
  if (!state.exitGrps) return state;

  const exitGrps = [...state.exitGrps];
  const id = exitGrps.findIndex((el) => el.id === action.exitGrp.id);

  if (action.type === actionTypes.UPDATE_EXIT_GROUP) {
    exitGrps[id] = { ...exitGrps[id], ...action.exitGrp };
  } else {
    exitGrps.splice(id, 1);
  }
};

const updateAttendance = (state, action) => {
  const attendances = [...state.attendances],
    eId = attendances.findIndex((el) => el._id === action.attendance.employee);
  if (eId >= 0) {
    const aId = attendances[eId].attendance.findIndex(
      (el) => el._id === action.attendance._id
    );
    if (aId >= 0)
    {
      if (attendances[eId].attendance[aId].remark !== action.attendance.remark)
				attendances[eId].attendance[aId].remark = action.attendance.remark;
			if (attendances[eId].attendance[aId].correctedHour !== action.attendance.correctedHour)
				attendances[eId].attendance[aId].correctedHour = action.attendance.correctedHour;
    }
      // attendances[eId].attendance[aId].remark = action.attendance.remark;
  }

  return updateObject(state, { ...initState, attendances });
};

const addDepartment = (state, action) => {
  const departments = [...state.departments];
  departments.push(action.department);
  return updateObject(state, { ...initState, departments });
};

const deleteDepartment = (state, action) => {
  if (!state.departments) return state;
  const departments = state.departments.filter((el) => el.id !== action.id);
  return updateObject(state, { ...initState, departments });
};

const updateDepartment = (state, action) => {
  if (!state.departments) return state;
  const departments = [...state.departments];

  const id = departments.findIndex(
    (el) => el[ID.id] === action.department[ID.id]
  );
  departments[id] = action.department;

  return updateObject(state, { ...initState, departments });
};

const updateDevice = (state, action) => {
  if (!state.devices) return state;
  const devices = [...state.devices];

  const id = devices.findIndex((el) => el[ID._id] === action.device[ID._id]);
  devices[id] = action.device;

  return updateObject(state, { ...initState, devices });
};

const addTelegroup = (state, action) => {
  let telegroups = action.telegroup;

  if (state.telegroups) telegroups = state.telegroups.concat(telegroups);

  return updateObject(state, { ...initState, telegroups });
};

const updateTelegroup = (state, action) => {
  let telegroup = action.telegroup;
  const telegroups = [...state.telegroups];
  const id = telegroups.findIndex(
    (el) => el[ID._id] === action.telegroup[ID._id]
  );

  if (action.type === actionTypes.UPDATE_TELEGROUP) {
    telegroups[id] = updateObject(state.telegroups[id], telegroup);
  } else {
    telegroups.splice(id, 1);
  }

  return updateObject(state, { ...initState, telegroups });
};

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.SET_LOADING:
      return updateObject(state, { loading: action.loading });
    case actionTypes.SET_ERROR:
      return updateObject(state, { error: action.error, loading: false });
    case actionTypes.SET_DIALOG:
      return updateObject(state, { dialog: action.dialog });
    case actionTypes.SET_HEADER:
      return updateObject(state, { headerText: action.text });
    case actionTypes.SET_USER:
      return updateObject(state, { ...initState, user: action.user });
    case actionTypes.SET_USERS:
      return updateObject(state, { ...initState, users: action.users });
    case actionTypes.ADD_USERS:
      return addUsers(state, action);
    case actionTypes.UPDATE_USERS:
    case actionTypes.DELETE_USERS:
      return updateUser(state, action);
    case actionTypes.SET_ATTENDANCES:
      return updateObject(state, {
        ...initState,
        attendances: action.attendances,
      });
    case actionTypes.UPDATE_ATTENDANCE:
      return updateAttendance(state, action);
    case actionTypes.ADD_EMPLOYEE:
      return addEmployee(state, action);
    case actionTypes.UPDATE_EMPLOYEE:
    case actionTypes.DELETE_EMPLOYEE:
      return updateEmployee(state, action);
    case actionTypes.SET_EMPLOYEES:
      return updateObject(state, { ...initState, employees: action.employees });
    case actionTypes.SET_DEPARTMENTS:
      return updateObject(state, {
        ...initState,
        departments: action.departments,
      });
    case actionTypes.DELETE_DEPARTMENT:
      return deleteDepartment(state, action);
    case actionTypes.ADD_DEPARTMENT:
      return addDepartment(state, action);
    case actionTypes.UPDATE_DEPARTMENT:
      return updateDepartment(state, action);
    case actionTypes.SET_DEVICES:
      return updateObject(state, { ...initState, devices: action.devices });
    case actionTypes.SET_SNAPSHOTS:     
      return updateObject(state, {...initState, snapshots: action.snapshots});
    case actionTypes.UPDATE_DEVICE:
      return updateDevice(state, action);
    case actionTypes.UPDATE_PAGE:
      return updatePage(state, action);
    case actionTypes.ADD_TELEGROUP:
      return addTelegroup(state, action);
    case actionTypes.SET_TELEGROUPS:
      return updateObject(state, {
        ...initState,
        telegroups: action.telegroups,
      });
    case actionTypes.UPDATE_TELEGROUP:
    case actionTypes.DELETE_TELEGROUP:
      return updateTelegroup(state, action);
    case actionTypes.SET_EXIT_GROUP:
      return updateObject(state, { ...initState, exitGrps: action.exitGrps });
    case actionTypes.DELETE_EXIT_GROUP:
      return updateExitGroup(state, action);
    case actionTypes.UPDATE_EXIT_GROUP:
      return updateExitGroup(state, action);
    default:
      return state;
  }
};

export default reducer;
