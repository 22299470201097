import { combineReducers } from '@reduxjs/toolkit';
import { reducer as calendarReducer } from '../slices/calendar';
import { reducer as dashboardReducer } from '../slices/dashboard';

const rootReducer = combineReducers({
  calendar: calendarReducer,
  dashboard: dashboardReducer
});

export default rootReducer;
