import {useEffect, useState} from "react";
import { makeStyles } from '@material-ui/core/styles';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import {FormLabel} from '@material-ui/core';

import {Radio} from '.';
import { LABEL, OPTIONS } from '../../../config/constants';

const useStyles = makeStyles(theme => ({
    root: {
        margin: theme.spacing(1),
        marginLeft: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
    },
    radios: {
        display: 'flex',
        flexDirection: 'row',
    }
}));

const Radios = props => {
    const classes = useStyles();
    const { id, value, required, disabled, style, onChange } = props;
    const options = OPTIONS[id];
    const [selected, setSelected] = useState('');

    useEffect(() => {
        value ? setSelected(value) : '';
    }, [value]);

    const changeHandler = event => {
        setSelected(event.target.value);
        onChange(event);
    }

    return (
        <FormControl
            className={classes.root}
            required={required}
            disabled={disabled}
        >
            <FormLabel component="legend">{LABEL[id]}</FormLabel>
            <RadioGroup
                row
                aria-label={id}
                name={id}
                value={selected}
                onChange={changeHandler}
                style={style}
                className={classes.radios}
            >
                {options.map((option, index) => <Radio key={index} text={option} />)}
            </RadioGroup>
        </FormControl>
    );
}

export default Radios;
