import {useNavigate} from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../../hooks/useAuth';

const RoleGuard = props => {
    const {roles, children} = props;
    const { user } = useAuth();
    const navigate = useNavigate();

    if (roles && (!user.role || !roles.includes(user.role))) {
        // console.log('[RoleGuard]', roles, user);
        return navigate('/');
    }
    return <>{children}</>;
};

RoleGuard.propTypes = {
    roles: PropTypes.array
};

export default RoleGuard;
