import {OutlinedInput} from '@material-ui/core';
import PropTypes from 'prop-types';
import {forwardRef} from 'react';

const style = {
    width: '3rem !important',
    height: '3rem',
    margin: '0 1rem',
    fontSize: '2rem',
    textAlign: 'center',
    borderRadius: '4px',
    border: '1px solid rgba(0, 0, 0, 0.3)'
}

const OTP = forwardRef((props, ref) => {
    const {id, value, onChange, inputFocus, autoFocus} = props;

    return (
        <OutlinedInput
            name={`otp${id}`}
            type="text"
            autoComplete="off"
            value={value || ''}
            onChange={e => onChange(e, id)}
            onKeyUp={e => inputFocus(e, id)}
            inputProps={{minLength:1,maxLength:1}}
            inputRef ={ref}
            autoFocus={autoFocus}
            sx={style}
        />
    );
})

OTP.propTypes = {
    id: PropTypes.number.isRequired,
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    inputFocus: PropTypes.func.isRequired
};

export default OTP;
