import styled from "@material-ui/core/styles/styled";
import MuiButton from "@material-ui/core/Button";
import spacing from '@material-ui/system/spacing';

const FullWidthButton = styled(({...props}) =>
    <MuiButton
        fullWidth
        color="primary"
        size="large"
        type="submit"
        variant="contained"
        {...props}
    />)(spacing);

export default FullWidthButton;
