import { useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  InputLabel,
  MenuItem,
  FormControl,
  ListItemText,
  Select,
  OutlinedInput,
} from "@material-ui/core";
import { LABEL, OPTIONS } from "../../../config/constants";
import Chip from "@material-ui/core/Chip";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(2),
    minWidth: 200,
    maxWidth: 600,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(option, items, theme) {
  return {
    fontWeight: items
      ? items.indexOf(option) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightBold
      : theme.typography.fontWeightRegular,
  };
}

const MultiSelect = (props) => {
  const { value, id, required, data, onChange } = props;
  const classes = useStyles();
  const theme = useTheme();
  const label = LABEL[id];
  // const options = OPTIONS[id];
  const options = data;
  const setValue = (value) => {
    return value ? value : [];
  };
  const [items, setItems] = useState(setValue(value));

  const handleChange = (event) => {
    setItems(event.target.value);
    onChange(event);
  };

  const body = (
    <FormControl variant="outlined" className={classes.formControl}>
      <InputLabel shrink>{label}</InputLabel>
      <Select
        id={id}
        multiple
        name={id}
        value={items}
        onChange={handleChange}
        required={required}
        input={<OutlinedInput notched label={label} />}
        renderValue={(selected) => (
          <div className={classes.chips}>
            {selected.map((value) => (
              <Chip key={value} label={value} className={classes.chip} />
            ))}
          </div>
        )}
        MenuProps={MenuProps}
      >
        {options
          ? options.map((option, id) => (
              <MenuItem
                key={id}
                value={option}
                style={getStyles(option, items, theme)}
              >
                {option}
              </MenuItem>
            ))
          : null}
      </Select>
    </FormControl>
  );

  return <div>{body}</div>;
};

export default MultiSelect;
