import {useEffect} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {Helmet} from 'react-helmet-async';
import {Box, Card, CardContent, Container, Divider, Link} from '@material-ui/core';

import {LoginJWT} from '../../components/authentication';
import Logo from '../../components/Logo';
import {URL} from '../../config/constants';
import sx from './styles';
import gtm from '../../lib/gtm';

const Login = () => {

    useEffect(() => {
        gtm.push({event: 'page_view'});
    }, []);

    const forgotPwd = (
        <Link
            color="textSecondary"
            component={RouterLink}
            to={URL.auth.base + URL.auth.forgotPwd}
            variant="body2"
        >Forgot password</Link>
    );

    return (
        <>
            <Helmet>
                <title>Login | Pensees</title>
            </Helmet>
            <Box sx={sx.body}>
                <Container maxWidth="sm" sx={{py: '80px'}}>
                    <Box sx={sx.logoBox}>
                        <Logo sx={sx.logo}/>
                    </Box>
                    <Card>
                        <CardContent sx={sx.main}>
                            <Box sx={sx.form}>
                                <LoginJWT/>
                            </Box>
                            <Divider sx={{my: 3}}/>
                            {forgotPwd}
                        </CardContent>
                    </Card>
                </Container>
            </Box>
        </>
    );
};

export default Login;
