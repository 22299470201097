import {useEffect, useRef, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Box, FormHelperText, Grid} from '@material-ui/core';

import useAuth from '../../hooks/useAuth';
import {MSG, UI} from '../../config/constants';
import {OTP} from '../widgets/input';
import {FullWidthButton} from '../widgets/buttons';

const VerifyOTP = () => {
    const navigate = useNavigate();
    const {verifyOtp} = useAuth();
    const [otp, setOtp] = useState({});
    const [isValid, setIsValid] = useState(false);
    const [error, setError] = useState(null);

    const inputFocus = (event, id) => {
        let next;
        switch (event.key) {
            case "Backspace":
            case "Delete":
            case "Tab":
                next = id;
                break;
            case "ArrowLeft":
                next = id - 1;
                break;
            default:
                next = id + 1;
        }

        if (next >= UI.otpLength)
            next = UI.otpLength - 1;
        else if (next < 0)
            next = 0;

        otpRef[next].current.focus();
    }

    const onChange = (event, id) =>
        setOtp(prev => {
            const updated = {
                ...prev,
                [id]: event.target.value
            }

            const values = Object.values(updated);
            values.length !== UI.otpLength || values.some(val => !val)
                ? setIsValid(false)
                : setIsValid(true);

            return updated;
        });

    const onSubmit = async event => {
        event.preventDefault();
        try {
            await verifyOtp(Object.values(otp).join(''));
            navigate('/');
        } catch (err) {
            console.error('[VerifyOTP.onSubmit]', err);
            setError(MSG.fail.otp);
        }
    }

    const otpRef = [], inputs = [];
    for (let i = 0; i < UI.otpLength; i++) {
        otpRef.push(useRef());
        inputs.push(
            <OTP
                key={i}
                id={i}
                value={otp[i]}
                ref={otpRef[i]}
                onChange={onChange}
                inputFocus={inputFocus}
                autoFocus={!i}
            />
        );
    }

    return (
        <form onSubmit={onSubmit}>
            <Box sx={{m: 2}}>
                <Grid container direction="row" justifyContent="center">
                    {inputs}
                </Grid>
                {error && (
                    <Box sx={{ml:5, mt:3}}>
                        <FormHelperText error>{error}</FormHelperText>
                    </Box>
                )}
            </Box>
            <Box sx={{mx:5, mt:2}}>
                <FullWidthButton
                    disabled={!isValid}
                    sx={{mt: 3}}
                >Submit</FullWidthButton>
            </Box>
        </form>
    )
};

export default VerifyOTP;
