import * as actionTypes from './actionTypes';
import {setLoading} from './common';
import axios from "../../lib/axios";
import {ID, URL} from '../../config/constants';

const setDevices = (dispatch, devices) => dispatch({type: actionTypes.SET_DEVICES, devices});
const editDevice = (dispatch, device) => dispatch({type: actionTypes.UPDATE_DEVICE, device});

export const getDevices = async dispatch => {
    setLoading(dispatch, true);
    const res = await axios.get(URL.api.device + '?active=true');
    console.log('[device.getDevices]', res.data);
    setDevices(dispatch, formatDevices(res.data));
};

export const syncDevices = async dispatch => {
    setLoading(dispatch, true);
    const res = await axios.get(URL.api.deviceSync);
    console.log('[device.syncDevices]', res.data);
    setDevices(dispatch, formatDevices(res.data));
};

export const updateDevice = async (dispatch, device) => {
    setLoading(dispatch, true);
    const res = await axios.put(
        URL.api.device + device[ID._id],
        formatDevice4Db(device)
    );
    console.log('[device.updateDevice]', res.data);
    editDevice(dispatch, device);
};

export const openDoor = async (dispatch, device) => {
    setLoading(dispatch, true);
    const res = await axios.get(URL.api.openDoor + device);
    // console.log('[device.opendoor] Response:', res.data);
};

const formatDevices = devices =>
    devices && devices.map(el => formatDevice(el));

const formatDevice = device => {
    const formatted = {...device};
    if(device.site)
        formatted[ID.site] = device.site._id;
    return formatted;
}

const formatDevice4Db = device => ({
    site: device.site,
    type: device.type
});
