import * as actionTypes from './actionTypes';
import {setLoading} from './common';
import axios from "../../lib/axios";
import {URL, ID} from '../../config/constants';

const addTelegroup = (dispatch, telegroup) => dispatch({type: actionTypes.ADD_TELEGROUP, telegroup});
const editTelegroup = (dispatch, telegroup) => dispatch({type: actionTypes.UPDATE_TELEGROUP, telegroup});
const removeTelegroup = (dispatch, telegroup) => dispatch({type: actionTypes.DELETE_TELEGROUP, telegroup});
const setTelegroups = (dispatch, telegroups) => dispatch({type: actionTypes.SET_TELEGROUPS, telegroups});

export const getTelegroups = async dispatch => {
    setLoading(dispatch, true);
    const res = await axios.get(URL.api.telegram);
    setTelegroups(dispatch, res.data);
};

export const createTelegroup = async (dispatch, telegroup) => {
    setLoading(dispatch, true);
    const res = await axios.post(
        URL.api.telegram,(telegroup)
    );
    console.log('[telegram.createTelegroup] Response:', res.data);
    addTelegroup(dispatch, res.data);
};

export const updateTelegroup = async (dispatch, telegroup) => {
    setLoading(dispatch, true);
    await axios.put(
        URL.api.telegram + telegroup[ID._id], telegroup
    );
    editTelegroup(dispatch, telegroup);
};

export const deleteTelegroup = async (dispatch, telegroup) => {
    setLoading(dispatch, true);
    await axios.delete(URL.api.telegram + telegroup[ID._id]);
    removeTelegroup(dispatch, telegroup);
};


export const testTelegroup= async (dispatch, telegroup) => {
    setLoading(dispatch, true);
    await axios.post(URL.api.telegram + telegroup[ID._id], telegroup);
};