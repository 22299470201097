import {useEffect, useState} from "react";
import {makeStyles} from '@material-ui/core/styles';
import {TextField} from '@material-ui/core';
import {LABEL, UI} from '../../../config/constants';
import {Tooltip} from '.';

const useStyles = makeStyles(theme => ({
    root: {
        // margin: theme.spacing(1),
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        boxSizing: 'border-box',
    },
    textarea: {
        margin: theme.spacing(2),
        resize: "both",
        width: UI.input.width,
        minWidth: UI.input.minWidth,
        maxWidth: UI.input.maxWidth,
      }
}));

const Textarea = props => {
    const classes = useStyles();
    const {id, disabled, label, required, visibility, rows, value, onChange, error, tip, placeholder} = props;
    const [text, setText] = useState(value ? value : '');

    useEffect(() => {
        !value ? setText('') : setText(value);
    }, [value]);

    const changeHandler = event => {
        setText(event.target.value);
        onChange(event);
    }

    const input = (
        <TextField
            name={id}
            label={label? label:LABEL[id]}
            required={required}
            disabled={disabled}
            visibility={visibility}
            value={text}
            onChange={changeHandler}
            error={error}
            placeholder={placeholder}
            className={classes.textarea}
            minRows={rows}
            multiline
            InputLabelProps={{
                shrink: true,
              }}
            // inputProps={{ className: classes.textarea }}
        />
    );

    return (
        <div>
            {tip ? <Tooltip tip={tip}>{input}</Tooltip> : input}
        </div>
    );
}

export default Textarea;
