import {TextField} from '@material-ui/core';

const Password = ({...props}) => (
    <TextField
        fullWidth
        label="Password"
        placeholder="Password"
        margin="normal"
        name="password"
        type="password"
        variant="outlined"
        {...props}
    />
);

export default Password;
