import * as actionTypes from './actionTypes';

export const setLoading = (dispatch, status) => dispatch({
    type: actionTypes.SET_LOADING,
    loading: status
});

export const setError = (dispatch, error) => dispatch({
    type: actionTypes.SET_ERROR,
    error
});

export const setHeaderText = (dispatch, text) => dispatch({
    type: actionTypes.SET_HEADER,
    text
});

export const setDialog = (dispatch, dialog) => dispatch({
    type: actionTypes.SET_DIALOG,
    dialog
});
