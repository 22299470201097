import {TextField} from '@material-ui/core';

const Email = ({...props}) => (
    <TextField
        autoFocus
        fullWidth
        label="Email Address"
        placeholder="Email Address"
        margin="normal"
        name="email"
        type="email"
        variant="outlined"
        {...props}
    />
);

export default Email;
