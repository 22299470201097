import { mock } from '../lib/axiosMock';

mock
  .onGet('/api/employees')
  .reply(() => {
    const employees = [
      {
        id: '5e887ac47eed253091be10cb',
        photo: '/static/mock-images/avatars/avatar-carson_darrin.png',
        name: 'Jason',
        department: 'CEO Office',
        occupation: 'CEO',
        reportTo: '',
        reportToId: '',
        empID:'10999',
        gender: 'Male',
        race: 'Eurasian',
        marital: 'Married',
        nationality: 'German',
        ic: 'Sxxx12345A',
        address1: 'Street John Wick, no. 7 House #25',
        address2: 'House #25',
        postCode: '240355',
        email: 'carson.darrin@devias.io',
        dateOfBirth: '1972-01-01',
        contactNo: '98765431',
        dateOfJoin: '2006-01-01',
        remark: ''
      },
      {
        id: '5e887b209c28ac3dd97f6db5',
        photo: '/static/mock-images/avatars/avatar-fran_perez.png',
        name: 'Francise Robert',
        department: 'Human Resource',
        occupation: 'Director',
        reportTo: 'Jason',
        reportToId: '10999',
        empID:'10529',
        gender: 'Male',
        race: 'Eurasian',
        marital: 'Married',
        nationality: 'German',
        ic: 'Sxxx12345B',
        address1: '',
        address2: '',
        postCode: '',
        email: 'fran.perez@devias.io',
        dateOfBirth: '1978-06-05',
        contactNo: '98764321',
        dateOfJoin: '2006-01-01',
        remark: ''
      },
      {
        id: '5e887b7602bdbc4dbb234b27',
        photo: '/static/mock-images/avatars/avatar-jie_yan_song.png',
        name: 'Jie Yan Song',
        department: 'Human Resource',
        occupation: 'Executive',
        reportTo: 'Francise Robert',
        reportToId: '10529',
        empID:'10542',
        gender: 'Female',
        race: 'Eurasian',
        marital: 'Married',
        nationality: 'German',
        ic: 'Sxxx12345C',
        address1: '',
        address2: '',
        postCode: '',
        email: 'jie.yan.song@devias.io',
        dateOfBirth: '1978-06-05',
        contactNo: '98764321',
        dateOfJoin: '2006-01-01',
        remark: ''
      },
      {
        id: '5e86809283e28b96d2d38537',
        photo: '/static/mock-images/avatars/avatar-jane_rotanson.png',
        name: 'Jane',
        department: 'Business Development',
        occupation: 'Director',
        reportTo: 'Carson Darrin',
        reportToId: '10999',
        empID:'10530',
        gender: 'Female',
        race: 'Eurasian',
        marital: 'Married',
        nationality: 'German',
        ic: 'Sxxx12345D',
        address1: '',
        address2: '',
        postCode: '',
        email: 'jane.rotanson@devias.io',
        dateOfBirth: '1978-06-05',
        contactNo: '98764321',
        dateOfJoin: '2006-01-01',
        remark: ''
      },
      {
        id: '5e86805e2bafd54f66cc95c3',
        photo: '/static/mock-images/avatars/avatar-miron_vitold.png',
        name: 'Mike',
        department: 'AI',
        occupation: 'Scientist',
        reportTo: 'Iulia Albu',
        reportToId: '10535',
        empID:'10531',
        gender: 'Male',
        race: 'Eurasian',
        marital: 'Married',
        nationality: 'German',
        ic: 'Sxxx12345E',
        address1: '',
        address2: '',
        postCode: '',
        email: '',
        dateOfBirth: '1978-06-05',
        contactNo: '98764321',
        dateOfJoin: '2006-01-01',
        remark: ''
      },
      {
        id: '5e887a1fbefd7938eea9c981',
        photo: '/static/mock-images/avatars/avatar-penjani_inyene.png',
        name: 'Zoe Tan',
        department: 'Business Development',
        occupation: 'Manager',
        reportTo: 'Jane Rotanson',
        reportToId: '10530',
        empID:'10532',
        gender: 'Female',
        race: 'Eurasian',
        marital: 'Married',
        nationality: 'German',
        ic: 'Sxxx12345F',
        address1: '',
        address2: '',
        postCode: '',
        email: '',
        dateOfBirth: '1978-06-05',
        contactNo: '98764321',
        dateOfJoin: '2006-01-01',
        remark: ''
      },
      {
        id: '5e887d0b3d090c1b8f162003',
        photo: '/static/mock-images/avatars/avatar-omar_darobe.png',
        name: 'Jaydon',
        department: 'AI',
        occupation: 'Senior Scientist',
        reportTo: 'Iulia Albu',
        reportToId: '10535',
        empID:'10533',
        gender: 'Male',
        race: 'Eurasian',
        marital: 'Married',
        nationality: 'German',
        ic: 'Sxxx12345G',
        address1: '',
        address2: '',
        postCode: '',
        email: '',
        dateOfBirth: '1978-06-05',
        contactNo: '98764321',
        dateOfJoin: '2006-01-01',
        remark: ''
      },
      {
        id: '5e88792be2d4cfb4bf0971d9',
        photo: '/static/mock-images/avatars/avatar-siegbert_gottfried.png',
        name: 'Aaron',
        department: 'AI',
        occupation: 'Scientist',
        reportTo: 'Iulia Albu',
        reportToId: '10535',
        empID:'10534',
        gender: 'Male',
        race: 'Eurasian',
        marital: 'Married',
        nationality: 'German',
        ic: 'Sxxx12345H',
        address1: '',
        address2: '',
        postCode: '',
        email: '',
        dateOfBirth: '1978-06-05',
        contactNo: '98764321',
        dateOfJoin: '2006-01-01',
        remark: ''
      },
      {
        id: '5e8877da9a65442b11551975',
        photo: '/static/mock-images/avatars/avatar-iulia_albu.png',
        name: 'Albert',
        department: 'AI',
        occupation: 'CTO',
        reportTo: 'CEO',
        reportToId: '10999',
        empID:'10535',
        gender: 'Female',
        race: 'Eurasian',
        marital: 'Married',
        nationality: 'German',
        ic: 'Sxxx12345I',
        address1: '',
        address2: '',
        postCode: '',
        email: '',
        dateOfBirth: '1978-06-05',
        contactNo: '98764321',
        dateOfJoin: '2006-01-01',
        remark: ''
      },
      {
        id: '5e8680e60cba5019c5ca6fda',
        photo: '/static/mock-images/avatars/avatar-nasimiyu_danai.png',
        name: 'Delores Lola',
        department: 'AI',
        occupation: 'Manager',
        reportTo: 'Iulia Albu',
        reportToId: '10535',
        empID:'10536',
        gender: 'Female',
        race: 'Eurasian',
        marital: 'Married',
        nationality: 'German',
        ic: 'Sxxx12345J',
        address1: '',
        address2: '',
        postCode: '',
        email: '',
        dateOfBirth: '1978-06-05',
        contactNo: '98764321',
        dateOfJoin: '2006-01-01',
        remark: ''
      },
    ];

    return [200, { employees }];
  });
